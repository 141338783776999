import React, { useEffect, useState } from "react";
import { FRContainer, FS, TColors } from "./DefaultComponents";
import Typo from "./Typo";
// import backgroundImage from './Image/backgroundImage.png'
// import backgroundImage from './UpdatedImages/BGImage.jpeg'
// import backgroundImage from './UpdatedImages/BannerBGImage.svg'
import backgroundImage from "./UpdatedImages/BGImage.svg"; //Zak:Change Image
// import backgroundImage from './Image/image8.png'
// import { Link } from "react-router-dom";
import { Ticon } from "../Screens/Header/HeaderRight";
// import { ScrollTop } from "./FunctionComponent";
import SS from "../WebData/ScreenSize";
import { ContactUsData } from "../WebData/ContentData";

export const TextInput1 = (props) => {
  return (
    <div style={{ ...styles.inputWrapper, ...props.styleWrapper }}>
      <input
        style={{
          ...styles.input,
          ...props.style,
        }}
        type={props.type ? props.type : "text"}
        value={props.value}
        onChange={props.onChange}
        placeholder={props.placeholder}
        defaultValue={props.defaultValue}
        readOnly={props.readOnly}
        maxLength={props.maxLength}
        multiple={true}
        required={props.required}
      />
    </div>
  );
};
export const TextInputTextArea = (props) => {
  return (
    <div style={{ ...styles.inputWrapper, ...props.styleWrapper }}>
      <textarea
        style={{
          ...styles.input,
          ...props.style,
        }}
        type={props.type ? props.type : "text"}
        value={props.value}
        onChange={props.onChange}
        placeholder={props.placeholder}
        defaultValue={props.defaultValue}
        readOnly={props.readOnly}
        maxLength={props.maxLength}
        required={props.required}
      />
    </div>
  );
};
export const NameNumber = (props) => {
  const [name, setName] = useState("");
  const [number, setNumber] = useState("");
  const [agreed, setAgreed] = useState(false);

  let validate = name.length > 2 && number.length > 9 ? true : false;

  const SubmitFunc = () => {
    setName("");
    setNumber("");
    setAgreed(false);
  };

  return (
    <div style={styles.NNWraper}>
      <FRContainer style={{ flexWrap: "wrap" }}>
        <div style={{ marginBottom: 10 }}>
          <TextInput1
            placeholder="NAME"
            onChange={(event) => setName(event.target.value)}
            value={name}
            style={{ textTransform: "uppercase", background: TColors.white }}
          />
        </div>
        <div style={{ marginBottom: 10 }}>
          <TextInput1
            placeholder="NUMBER"
            type="tel"
            onChange={(event) => setNumber(event.target.value)}
            value={number}
            maxLength={10}
            style={{ background: TColors.white }}
          />
        </div>
      </FRContainer>
      {!validate ? null : (
        <FRContainer style={{ flexDirection: "column" }}>
          <FRContainer>
            <input
              onClick={() => setAgreed(!agreed)}
              type="checkbox"
              style={{
                height: FS.h3,
                width: FS.h3,
                marginRight: 10,
                borderRadius: "50%",
              }}
            />
            <span style={{ ...Typo.h6 }}>
              I agree that the submitted data is being collected and stored and
              I consent to the collection and use of my personal information.
            </span>
          </FRContainer>
          <CustomButton
            clicked={agreed}
            btnText="Submit"
            btnClick={() => SubmitFunc()}
          />
        </FRContainer>
      )}
    </div>
  );
};
export const CustomButton = (props) => {
  return (
    <div
      onClick={() => (props.disable ? null : props.btnClick())}
      style={{
        ...styles.btnWrapper,
        color: props.clicked ? TColors.blue : TColors.primary,
        ...props.style,
      }}
    >
      <span style={{ ...Typo.h4, ...props.txtStyle }}>{props.btnText}</span>
    </div>
  );
};
export const BannerUI = (props) => {
  // let active = props.active;
  return (
    <div
      style={{
        ...styles.BUIWrapper,
        backgroundImage: props.bannerImage
          ? props.bannerImage
          : `url(${backgroundImage})`,
      }}
    >
      <div style={styles.BUISubWrapper}>
        {/* <span
                    style={{
                        ...Typo.h1,
                        ...styles.BUITitle
                    }}>
                    {active}
                </span> */}
        <FRContainer style={{ minWidth: "20%", justifyContent: "left" }}>
          {/* <Link
                        to="/"
                        onClick={() => ScrollTop()}
                        style={{
                            color: TColors.white, ...Typo.h6, marginRight: 30
                        }}
                    >Home
                    </Link> */}
          {/* <span
                        style={{
                            color: TColors.white,
                            ...Typo.h6,
                            marginleft: "25vw"
                        }}>
                        {active}
                    </span> */}
        </FRContainer>
      </div>
    </div>
  );
};
export const CloseButton = (props) => {
  const [close, setClsoe] = useState(false);
  useEffect(() => {
    if (close) {
      props.closeB(false);
    }
  }, [close]);
  return (
    <div
      onClick={() => setClsoe(true)}
      style={{
        position: "fixed",
        top: 15,
        right: 10,
        ...props.style,
        cursor: "pointer",
      }}
    >
      <Ticon
        iconName="fa fa-times"
        style={{ color: TColors.primary, fontSize: FS.h2 }}
      />
    </div>
  );
};
export const IndexHeader = (props) => {
  return (
    <div style={styles.IHWrapper}>
      <span style={{ ...Typo.bgNumber }}>{props.number}</span>
      <div style={styles.IHSubWrapper}>
        <div style={styles.IHTitleWrapper}>
          <span
            style={{
              ...Typo.bgText,
              color: TColors.black1,
              marginRight: "3%",
            }}
          >
            {props.title1}
          </span>
          <span
            style={{
              ...Typo.bgText,
              color: TColors.blue,
            }}
          >
            {props.title2}
          </span>
        </div>
        <span style={{ ...Typo.h4, textAlign: "center" }}>
          {props.titleFooter}
        </span>
      </div>
    </div>
  );
};
export const WhiteBackground = (props) => {
  return (
    <div style={{ ...styles.WBWrapper, ...props.style }}>{props.children}</div>
  );
};
export const FindYouOrders = (props) => {
  return (
    <FRContainer
      style={{
        width: "100%",
        backgroundColor: TColors.white,
        minHeight: 150,
        flexDirection: "column",
        alignItems: "flex-start",
        paddingLeft: "10vw",
      }}
    >
      <span style={{ ...Typo.h1, color: TColors.black1 }}>
        FIND YOUR ORDERS?
      </span>
      <a
        style={{ textDecoration: "none" }}
        href={"tel:" + ContactUsData.Mobile}
      >
        <span style={{ ...Typo.h1, color: TColors.blue }}>
          {ContactUsData.Mobile}{" "}
        </span>
      </a>
    </FRContainer>
  );
};
const styles = {
  inputWrapper: {
    minHeight: "6.5vh",
    backgroundColor: TColors.white,
    display: "flex",
    alignItems: "center",
    paddingRight: 25,
    paddingLeft: 20,
    borderRadius: 50,
    // maxWidth: '50vw',
    width: "80%",
    flex: 1,
    marginBottom: 10,
    // border: 'outset',
    // borderColor: TColors.black1
  },
  input: {
    display: "flex",
    flex: 1,
    backgroundColor: "transparent",
    borderWidth: 0,
    borderColor: TColors.blue,
    ...Typo.p,
    fontWeight: 600,
    outline: "none",
    minWidth: 10,
    marginBottom: 10,
    marginTop: 10,
    // paddingTop:10,
    // paddingBottom:10,
    // paddingLeft: 5,
    // paddingRight: 5,
    // borderRadius:4,
    textTransform: "uppercase",
  },
  NNWraper: {
    maxWidth: "100%",
    minWidth: "50%",
    width: "auto",
  },
  btnWrapper: {
    marginTop: 10,
    color: TColors.primary,
    minHeight: "6.5vh",
    backgroundColor: TColors.grey,
    width: "fit-content",
    display: "flex",
    alignItems: "center",
    paddingRight: 25,
    paddingLeft: 20,
    borderRadius: 50,
    cursor: "pointer",
  },
  IHWrapper: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "auto",
    minHeight: window.innerWidth > 400 ? "20em" : "15em",
  },
  IHSubWrapper: {
    zIndex: 1,
    display: "flex",
    flexDirection: "column",
    width: "60%",
    justifyContent: "center",
    alignItems: "center",
  },
  IHTitleWrapper: {
    display: "flex",
    flexWrap: "wrap",
    width: "100%",
    justifyContent: "center",
  },
  BUIWrapper: {
    backgroundColor: TColors.background,
    display: "flex",
    width: "100%",
    minHeight: window.innerHeight <= 480 ? "50vh" : "40vh",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    alignItems: "center",
    // marginTop: 40
  },
  BUISubWrapper: {
    display: "flex",
    marginLeft: "20%",
    flexDirection: "column",
    flex: 1,
    // marginTop: window.innerHeight <= 480? 25   : 15
    // background:'red'
  },
  BUITitle: {
    color: TColors.white,
    marginBottom: 12.5,
    textDecoration: "none",
    fontSize: SS().isMobile ? FS.h4 : FS.h1,
  },
  WBWrapper: {
    flex: 1,
    marginRight: "2vw",
    marginLeft: "2vw",
    marginBottom: "5vh",
    backgroundColor: TColors.white,
    minHeight: "5vh",
    marginTop: 0,
    borderRadius: 5,
    maxWidth: "93vw",
    padding: 20,
  },
};
