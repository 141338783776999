import React from 'react'
import { FRContainer, FS, TColors } from '../../../Components/DefaultComponents'
import Typo from '../../../Components/Typo'
import { WhiteBackground } from '../../../Components/UIComponent'
import { ContactUsData } from '../../../WebData/ContentData'
import {  Ticon } from '../../Header/HeaderRight'

const AddressComponent = () => {
    return (
        <WhiteBackground style={{ ...styles.wrapper }}>
            <FRContainer style={{ flexDirection: 'column', alignItems: "flex-start", height: "auto", width: "fit-content" }}>
                <FRContainer style={{ justifyContent: "flex-start", marginBottom: 20 }}>

                    <Ticon
                        iconName="fa fa-map-marker"
                        style={{ ...styles.icon, fontSize: FS.h2 }}
                    />
                    <a href="http://maps.google.com" style={{ textDecoration: "none" }}>
                        <span style={{ ...Typo.h5, color: TColors.black1, whiteSpace: "pre-wrap" }}>
                            {ContactUsData.Address}
                        </span>
                    </a>
                </FRContainer>
                <FRContainer style={{ justifyContent: "flex-start", marginBottom: 20 }}>
                    <Ticon
                        iconName="fa fa-envelope"
                        style={{ ...styles.icon, fontSize: FS.h5 }}
                    />
                    <a
                        href={"mailto:" + ContactUsData.EmailId}
                        style={{ textDecoration: "none" }}>
                        <span style={{ ...Typo.h5, color: TColors.black1 }}>
                            {ContactUsData.EmailId}
                        </span>
                    </a>
                </FRContainer>
                {/* <FRContainer style={{ justifyContent: "flex-start", marginBottom: 20 }}>
                    <Ticon
                        iconName="fa fa-phone"
                        style={{ ...styles.icon }}
                    />
                    <a
                        href={"tel:" + ContactUsData.Telephone}
                        style={{ textDecoration: "none" }}>
                        <span style={{ ...Typo.h5, color: TColors.black1 }}>
                            {ContactUsData.Telephone}
                        </span>
                    </a>
                </FRContainer> */}
                <FRContainer style={{ justifyContent: "flex-start", marginBottom: 10 }}>
                    <Ticon
                        iconName="fa fa-mobile"
                        style={{ ...styles.icon, fontSize: FS.h2 }}
                    />
                    <a
                        href={"tel:" + ContactUsData.Mobile}
                        style={{ textDecoration: "none" }}>
                        <span style={{ ...Typo.h5, color: TColors.black1 }}>
                            {ContactUsData.Mobile}
                        </span>
                    </a>
                </FRContainer>
            </FRContainer>
            {/* <FRContainer style={{ marginTop: 30 }}>
                <IconsMenu style={{ fontSize: FS.h2, color: TColors.blue }} />
            </FRContainer> */}
        </WhiteBackground>
    )
}

const styles = {
    wrapper: {
        flex: 1,
        display: "flex",
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: TColors.grey,
    },
    icon: {
        fontSize: FS.h4,
        width: 15,
        color: TColors.primary
    }
}

export default AddressComponent
