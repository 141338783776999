const WebLeads = async (partRequestObj, contactUsObj) => {
   console.log("ContactUs: " + contactUsObj);
  console.log("PartRequest: " + partRequestObj);
  // console.log("FETCH URL: " + `${process.env.REACT_APP_PUBLIC_GAM_URL}webLead`);
  fetch(`${process.env.REACT_APP_PUBLIC_UPLOAD_URL}/webLead`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      //Authorization: `Bearer ${idToken}`,
      
    },
    body: JSON.stringify({
      LeadSource: "Web",
      Status: "Web Lead",
      Name:
        partRequestObj && partRequestObj.name
          ? partRequestObj.name
          : contactUsObj && contactUsObj.name
          ? contactUsObj.name
          : "",
      Mobile:
        partRequestObj && partRequestObj.number
          ? partRequestObj.number
          : contactUsObj && contactUsObj.number
          ? contactUsObj.number
          : "",
      Email:
        partRequestObj && partRequestObj.email
          ? partRequestObj.email
          : contactUsObj && contactUsObj.email
          ? contactUsObj.email
          : "",
      Subject: contactUsObj && contactUsObj.subject ? contactUsObj.subject : "",
      Message: contactUsObj && contactUsObj.message ? contactUsObj.message : "",

      Part: partRequestObj && partRequestObj.part ? partRequestObj.part : "",
      Make: partRequestObj && partRequestObj.make ? partRequestObj.make : "",
      Model: partRequestObj && partRequestObj.model ? partRequestObj.model : "",
      Year: partRequestObj && partRequestObj.year ? partRequestObj.year : "",
      Zip: partRequestObj && partRequestObj.zip ? partRequestObj.zip : "",
      //User: "ahmed17projects@gmail.com",
      //User: localStorage.getUserEmail(),
    }),
  })
    .then((res) => res.text())
    .then((resl) => {
      //setLoad(false);
      console.log("FINAL RESULT : " + resl);
    })
    .catch((e) => {
      //setLoad(false);

      console.log(e, "WebLead fetch error");
    });
};
export default WebLeads;
