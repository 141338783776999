import React, { useState } from 'react'
import { FRContainer, FS, TColors, Text } from '../../Components/DefaultComponents'
import Typo from '../../Components/Typo'
// import logo from '../../Components/Image/logo.png'
import logo from '../../Components/UpdatedImages/logo.svg'
import SS from '../../WebData/ScreenSize'

const Logo = (props) => {
    const [smallScreen] = useState(SS().smallScreen)
    return (
        <FRContainer style={{ marginRight: 0 }}>
            <Text
                style={{
                    color: props.color ? TColors.blue : TColors.primary,
                    ...Typo.h2,
                    fontSize: smallScreen? FS.h7: window.innerWidth <= 600 ? '2em' : FS.h2,

                }}><strong >
                    <FRContainer>

                        {/* {window.innerWidth <= 300 ?
                    'G '
                    :
                    'Get '
                } */}
                        <img style={{ height: FS.h6, marginRight: 5 }} src={logo} alt="not loaded" />
                        GetAutoMoto
                        {/* Get */}
                        {/* {window.innerWidth <= 300 ?
                    ' AM'
                    :
                    ' AutoMoto'
                } */}
                        {/* AutoMoto */}
                    </FRContainer>

                </strong></Text>
        </FRContainer>
    )
}

export default Logo
