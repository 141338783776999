import React from "react";
const FlexBox = ({
  children,
  bgColor,
  direction,
  jc,
  ai,
  mTop,
  mBot,
  mLeft,
  mRight,
  margin,
  padding,
  pBot,
  pTop,
  pRight,
  pLeft,
  width,
  height,
  minWidth,
  flexWrap,
  minHeight,
  maxWidth,
  maxHeight,
  borderBottomWidth,
  borderBottomColor,
  borderBottomStyle,
  borderTopWidth,
  borderTopColor,
  borderTopStyle,
  borderLeftWidth,
  borderLeftColor,
  borderLeftStyle,
  borderRightWidth,
  borderRightColor,
  borderRightStyle,
  borderWidth,
  borderColor,
  borderStyle,
  onMouseEnter,
  onMouseLeave,
  columnGap,
  onClick,
  display,
  gap,
  id,
  index,
  flexBoxStyles,
  borderRadius,
}) => {
  return (
    <div
      key={index}
      id={id}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      onClick={onClick}
      style={{
        display: display ? display : "flex",
        flexDirection: direction ? direction : "row",
        backgroundColor: bgColor,
        justifyContent: jc ? jc : "center",
        alignItems: ai ? ai : "center",
        margin: margin,
        marginTop: mTop,
        marginBottom: mBot,
        marginLeft: mLeft,
        marginRight: mRight,
        padding: padding,
        paddingBottom: pBot,
        paddingTop: pTop,
        paddingRight: pRight,
        paddingLeft: pLeft,
        width: width,
        height: height,
        minWidth: minWidth,
        flexWrap: flexWrap,
        minHeight: minHeight,
        maxWidth: maxWidth,
        maxHeight: maxHeight,
        borderWidth: borderWidth,
        borderColor: borderColor,
        borderStyle: borderStyle,
        borderBottomWidth: borderBottomWidth,
        borderBottomColor: borderBottomColor,
        borderBottomStyle: borderBottomStyle,
        borderTopWidth: borderTopWidth,
        borderTopColor: borderTopColor,
        borderTopStyle: borderTopStyle,
        borderLeftWidth: borderLeftWidth,
        borderLeftColor: borderLeftColor,
        borderLeftStyle: borderLeftStyle,
        borderRightWidth: borderRightWidth,
        borderRightColor: borderRightColor,
        borderRightStyle: borderRightStyle,
        columnGap: columnGap,
        gap: gap,
        borderRadius: borderRadius,
        cursor: onClick && "pointer",
        ...flexBoxStyles,
      }}
    >
      {children}
    </div>
  );
};

export default FlexBox;
