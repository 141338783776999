import React from "react";
import CustomText from "../GlobalComponents/CustomText";
import FlexBox from "../GlobalComponents/FlexBox";
import Logo from "./Header/Logo";

const NoPage = () => {
  return (
    <FlexBox direction={"column"} gap={20} height="100vh">
      <Logo />
      <FlexBox gap={10} flexWrap="wrap">
        <CustomText>404 Error</CustomText>
        <CustomText>Page Not Found</CustomText>
      </FlexBox>
    </FlexBox>
  );
};

export default NoPage;
