import React, { useState } from "react";
import { FRContainer, FS, TColors } from "../../Components/DefaultComponents";
import Typo from "../../Components/Typo";
import {  WhiteBackground } from "../../Components/UIComponent";
import { AboutUSData, ServicesData } from "../../WebData/ContentData";
import SS from "../../WebData/ScreenSize";
import {  Ticon } from "../Header/HeaderRight";
import SocialMediaBG from "../../Components/Image/SocialMediaBG.png";
import keyFeatureImg from "../../Components/Image/keyFeatureImg.png";
import { ProductDetailsModal } from "../../Components/CustomModal";

export const AboutUsKeyFeature = () => {
  return (
    <FRContainer style={{ flexWrap: "wrap" }}>
      <FRContainer
        style={{
          flexWrap: "wrap",
          maxWidth: "93vw",
          justifyContent: "space-evenly",
          alignItems: "stretch",
        }}
      >
        <FRContainer
          style={{ flexDirection: "column", flex: 1, minWidth: 300 }}
        >
          <span
            style={{
              ...Typo.p,
              maxWidth: "80vw",
              marginBottom: "2em",
              wordBreak: "break-word",
            }}
          >
            {AboutUSData.keyFeatures.description}
          </span>
          {AboutUSData.keyFeatures.features.map((e, i) => (
            <FRContainer
              key={i}
              style={{ width: "100%", justifyContent: "flex-start" }}
            >
              <Ticon
                iconName={e.icon}
                style={{ color: TColors.primary, fontSize: FS.h1 }}
              />
              <FRContainer
                style={{
                  width: "-webkit-fill-available",
                  flexDirection: "column",
                  alignItems: "flex-start",
                }}
              >
                <span style={{ ...Typo.h5, marginBottom: "0.5em" }}>
                  {e.title}
                </span>
                <span style={{ ...Typo.p, marginBottom: "2em" }}>
                  {e.smallDescription}
                </span>
              </FRContainer>
            </FRContainer>
          ))}
        </FRContainer>

        {/* <img style={{ flex: 1, marginTop: '2em', maxHeight: '50vh' }} alt="not loaded" src={keyFeatureImg} /> */}
        <FRContainer
          style={{
            flex: 1,
            backgroundImage: `url(${keyFeatureImg})`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            backgroundPosition: "center",
            justifyContent: "center",
            minWidth: 300,
            minHeight: 300,
          }}
        ></FRContainer>
      </FRContainer>
    </FRContainer>
  );
};
export const AboutUsText = () => {
  return (
    <WhiteBackground>
      <FRContainer style={{ flexDirection: SS().isMobile ? "column" : "row" }}>
        <FRContainer
          style={{
            alignItems: "flex-start",
            flexDirection: "column",
            padding: 20,
            paddingBottom: SS().isMobile ? 10 : 20,
          }}
        >
          <span style={{ ...Typo.h5, marginBottom: 10 }}>
            {AboutUSData.General.title}
          </span>
          <span
            style={{ ...Typo.h6, textTransform: "uppercase", marginBottom: 10 }}
          >
            {AboutUSData.General.subTitile}
          </span>
          <span
            style={{
              ...Typo.p,
              marginBottom: SS().isMobile ? 0 : 10,
              // lineHeight: 10,
            }}
          >
            {AboutUSData.General.content.p1}
          </span>
        </FRContainer>
        <FRContainer
          style={{
            alignItems: "flex-start",
            flexDirection: "column",
            padding: 20,
            paddingTop: SS().isMobile ? 0 : 20,
          }}
        >
          <span style={{ ...Typo.p, marginBottom: 10, lineHeight: 1 }}>
            {AboutUSData.General.content.p2}
          </span>
          <span style={{ ...Typo.p, marginBottom: 10, lineHeight: 1 }}>
            {AboutUSData.General.content.p3}
          </span>
        </FRContainer>
      </FRContainer>
    </WhiteBackground>
  );
};
export const AboutUSTestimonals = (props) => {
  return (
    <FRContainer
      style={{
        width: "100%",
        backgroundImage: `url(${SocialMediaBG})`,
        height: "50vh",
        zIndex: -1,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        backgroundPosition: "center",
        justifyContent: "center",
        backgroundBlendMode: "darken",
        alignItems: "stretch",
      }}
    >
      {/* <FRContainer style={{ flexWrap: "wrap", justifyContent: 'space-evenly', width: "90%", }}>
                <IconsMenu style={{
                    fontSize: window.innerWidth < 400 ? '20vw' : '15vw',
                    color: TColors.white,
                    minWidth: 100,
                    display: 'flex',
                    justifyContent: 'center',
                    margin: 0,

                }} />
            </FRContainer> */}
    </FRContainer>
  );
};
export const AboutUsWhatWeDo = (props) => {
  const [open, setOpen] = useState(false);
  const [data] = useState();
  const [modalType] = useState();

  // const OpenModal = (e) => {
  //   setData(e);
  //   setOpen(true);
  //   setModalType("ProductDetailsModal");
  //   console.log(e);
  // };
  return (
    <FRContainer>
      <FRContainer
        style={{
          flexWrap: "wrap",
          width: "80%",
          minHeight: 300,
          justifyContent: "space-between",
          alignItems: "stretch",
          gap: 20,
          paddingTop: 30,
        }}
      >
        {open && modalType === "ProductDetailsModal" ? (
          <ProductDetailsModal
            open={open}
            btnClose={() => setOpen(false)}
            passingData={data}
          />
        ) : null}
        <FRContainer
          style={{
            width: "100%",
            justifyContent: "space-evenly",
            flexWrap: "wrap",
            alignItems:'stretch',
            paddingBottom:20
          }}
        >
          {ServicesData.Services.map((e, i) => (
            <AboutUsServiceContainer
              key={i}
              src={e.image}
              title={e.title}
              description={e.smallDescription}
              // btnClick={() => OpenModal(e)}
            />
          ))}
        </FRContainer>
      </FRContainer>
    </FRContainer>
  );
};
export const AboutUsServiceContainer = (props) => {
  return (
    <FRContainer
      style={{
        flexDirection: "column",
        backgroundColor: TColors.grey,
        borderRadius: 6,
        // margin: 20,
        // paddingBottom: 20,
        justifyContent: "flex-start",
        marginTop: 20,
      }}
    >
      <FRContainer
        style={{
          flexDirection: "column",
          // width: window.innerWidth < 350 ? window.innerWidth * 80 / 100 : 200,
          width: "auto",
          justifyContent: "space-between",
          // flex: 1
          maxWidth: 250,
        }}
      >
        <img
          style={{
            // width: 'inherit',
            width: "auto",
            height: 200,
            maxWidth: 250,
            display: "flex",
            objectFit: "cover",
          }}
          alt="not load"
          src={props.src}
        />

        <FRContainer
          style={{
            flexDirection: "column",
            margin: 20,
          }}
        >
          <span
            style={{
              ...Typo.h3,
              color: TColors.blue,
              marginBottom: 10,
              textAlign: "center",
            }}
          >
            {props.title}
          </span>
          {/* <span style={{ ...Typo.h5 }}>{props.description}</span> */}
        </FRContainer>
        {/* <CustomButton
                    style={{ backgroundColor: TColors.blue, color: TColors.white, marginTop: 40 }}
                    btnText='View More'
                    btnClick={() => props.btnClick()} /> */}
      </FRContainer>
    </FRContainer>
  );
};
export const AboutUsPartners = (props) => {
  return (
    <WhiteBackground>
      <FRContainer style={{ flexWrap: "wrap" }}>
        <img
          style={{ width: "100%" }}
          alt="not loaded"
          src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRi1t1vK1H_0DXVY676jYqfWf4di7wleIvcIg&usqp=CAU"
        />
      </FRContainer>
    </WhiteBackground>
  );
};
