import React from 'react'
import Header from '../Header/Header'
import { BannerUI, IndexHeader } from '../../Components/UIComponent'
import { AboutUsKeyFeature, AboutUsText, AboutUsWhatWeDo } from './AboutUsComponent'
// import servicesImg from '../../Components/Image/servicesImg.png'
// import RequestContainer from '../Home/HomeComponents/RequestContainer'
// import { FRContainer } from '../../Components/DefaultComponents'

const AboutUs = () => {
    return (
        <div style={{ width: '100%', minHeight: "100vh", }}>
            <Header active="About us" />
            <BannerUI active="About us" />
            <IndexHeader
                number="01"
                title1="KEY"
                title2="FEATURES"
            />
            <AboutUsKeyFeature />

            <IndexHeader
                number="02"
                title1="WHO WE"
                title2="ARE"
                titleFooter="Some facts about us"
            />
            <AboutUsText />

            {/* <IndexHeader
                number="03"
                title1="WHY US?"
                title2="TESTIMONIALS"
            />
            <AboutUSTestimonals /> */}

            <IndexHeader
                number="03"
                title1="WHAT WE"
                title2="DO"
                titleFooter="Our services"
            />
            {/* <img
                src={servicesImg} alt='load error'
                style={{
                    width: '100%',
                }} /> */}
            <AboutUsWhatWeDo />

            {/* <IndexHeader
                number="04"
                title1="Our"
                title2="Partners"
                titleFooter="Trusted by thousands"
            />
            <AboutUsPartners /> */}

            {/* <IndexHeader
                number="04"
                title1="Find your "
                title2="own Style"
                titleFooter="Perfect parts for your vehicle"
            />
            <FRContainer style={{ justifyContent: "center", marginBottom: 50, marginTop: 20 }} >
                <RequestContainer
                    style={{ marginTop: 0 }}
                    content="Our professional team will help you choose perfect parts for your vehicle" />
            </FRContainer> */}
        </div>
    )
}

export default AboutUs
