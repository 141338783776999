import engineImg from "../Components/UpdatedImages/Engine.svg";
// import engineImg from '../Components/Image/engineImg.png'
import axleImg from "../Components/UpdatedImages/Axle.svg";
// import axleImg from '../Components/Image/axleImg.png'
import transmissionImg from "../Components/UpdatedImages/transmission.svg";
// import transmissionImg from '../Components/Image/transmissionImg.png'
import transferCaseImg from "../Components/UpdatedImages/TransferCase.svg";
// import transferCaseImg from '../Components/Image/transferCaseImg.png'

import lightImg from "../Components/UpdatedImages/Lights.svg";
// import lightImg from '../Components/Image/lightImg.png'
import spindleImg from "../Components/UpdatedImages/knuckle.svg";
// import spindleImg from '../Components/Image/spindleImg.png'
import StarterImg from "../Components/UpdatedImages/Starter.svg";
// import StarterImg from '../Components/Image/StarterImg.png'
import regulatorImg from "../Components/UpdatedImages/regulator.svg";
// import regulatorImg from '../Components/Image/regulatorImg.png'
import blowerImg from "../Components/UpdatedImages/BlowerMotor.svg";
// import blowerImg from '../Components/Image/blowerImg.png'
import controlModuleImg from "../Components/UpdatedImages/ECM.svg";
// import controlModuleImg from '../Components/Image/controlModuleImg.png'
import cylinderHeadImg from "../Components/UpdatedImages/CylinderHead.svg";
// import cylinderHeadImg from '../Components/Image/cylinderHeadImg.png'
import engineAssembleImg from "../Components/UpdatedImages/Engine.svg";
// import engineAssembleImg from '../Components/Image/engineAssembleImg.png'
// import repairPartsImg from "../Components/Image/repairPartsImg.png";
// import greyBGImg from "../Components/UpdatedImages/Otherparts.svg";
// import greyBGImg from '../Components/Image/greyBGImg.png'

// import CertifiedServiceImg from "../Components/Image/CertifiedServiceImg.png";
import QualityAUtoPartsImg from "../Components/UpdatedImages/OEM.svg";
// import QualityAUtoPartsImg from '../Components/Image/QualityAUtoPartsImg.png'
import WarrantyIssuesHandelingImg from "../Components/UpdatedImages/Warranty.svg";
// import WarrantyIssuesHandelingImg from '../Components/Image/WarrantyIssuesHandelingImg.png'
// import DealerPriceImg from "../Components/Image/DealerPriceImg.png";
import QuickQuotesImg from "../Components/UpdatedImages/Quote.svg";
// import QuickQuotesImg from '../Components/Image/QuickQuotesImg.png'
import FastDeliveryImg from "../Components/UpdatedImages/FastDelivery.svg";
// import FastDeliveryImg from '../Components/Image/FastDeliveryImg.png'
// import CertifiedMechaniciMG from '../Components/Image/CertifiedMechaniciMG.png'

export const MenuData = {
  HeaderMenuData: [
    {
      title: "About us",
      link: "/aboutus",
    },
    {
      title: "Contact us",
      link: "/contactus",
    },
    {
      title: "Part Request",
      link: "/partrequest",
    },
    // {
    //   title: "Policies",
    //   link: "/policies",
    // },
    // {
    //   title: "Terms of use",
    //   link: "/terms",
    // },
  ],
  HeaderMenuDropList: [
    {
      title: "Part Request",
      link: "/partrequest",
    },
    {
      title: "Contact Us",
      link: "/contactus",
    },
  ],

  AllMenuData: [
    {
      title: "Home",
      link: "/",
    },
    {
      title: "About us",
      link: "/aboutus",
    },
    {
      title: "Contact us",
      link: "/contactus",
    },
    {
      title: "Part Request",
      link: "/partrequest",
    },
    {
      title: "Policies",
      link: "/policies",
    },
    {
      title: "Terms of use",
      link: "/terms",
    },
  ],

  SocialIcons: [
    {
      iconName: "fa fa-facebook-square",
      iconLink: "https://www.facebook.com/",
    },
    {
      iconName: "fa fa-twitter",
      iconLink: "https://twitter.com/?lang=en",
    },
    {
      iconName: "fa fa-instagram",
      iconLink: "https://www.instagram.com/",
    },
    {
      iconName: "fa fa-youtube",
      iconLink: "https://www.youtube.com/",
    },
  ],
};
export const HomeContentData = {
  welcomeContent: {
    p1: "Welcome to GetAutoMoto parts, your go-to destination for all things related to automobiles, we offer top-quality used OEM auto parts and accessories with unparalleled convenience and reliability. We strive to bring you the latest automobile parts, all in one place. We carry a comprehensive range of vehicle parts to suit any make and model. Whether you're looking for parts for your car, van or truck, we've got you covered. Our team of experts is always available to help you find the right part for your vehicle. We understand that every vehicle is unique, and we take the time to listen to your specific needs to ensure that you get the right part for your vehicle.",
    p2: "We are headquartered in Chicago, IL, with suppliers across North America.Our goal is to find you the correct part at an inexpensive price, close to your home or business.We can help you locate those hard-to-find parts.",
  },
};
export const HoemWelcomeBannerData = [
  {
    title: "Engine",
    description:
      "“GetAutoMoto parts offers high-quality rebuilt engines that run smoothly and are priced lower than the competition. Our engines come with lower miles and are built to last. We supply a wide selection of engines available for purchase, so you can find the perfect one for your vehicle. Our team of experts is always available to help you find the right engine for your needs. We pride ourselves on providing excellent customer service and quality products. Shop with us today and experience the difference!”",
    // "Purchase high quality, Rebuilt, smooth running Engines, with lesser price and lower miles",
    link: "",
    image: engineImg,
    details: {
      title: "Neccessary Information",
      points: [
        "Perform a full tune-up (spark-plugs, wires, air filters, hoses, belts)",
        "Replace engine oil to manufacturers suggested levels",
        "Replace oil filter",
        "Replace gaskets and swap as needed (intake and exhaust manifold, valve cover, oil pan & rear main seal)",
        "Inspect and replace(if needed) new timing belt/ chain",
        "Replace thermostat and water pump",
        "Flush the cooling system and refill with antifreeze to appropriate level",
        "Swap over original wiring harness with sensors",
        "Swap over or replace all bolt- on accessories(alternator, starter, AC compressor, etc)",
        "Change the oil after the first 600 miles and at recommended service intervals thereafter",
        "Service the vehicle at proper intervals as per manufacturer's guidelines",
      ],
      note: "The exhaust manifold will not be included in cases where the catalytic converter cannot be detached from it. \n * It is forbidden by law.",
    },
  },
  {
    title: "Transmission",
    description:
      "“GetAutoMoto offers a wide assortment of manual and automatic transmissions to help you avoid jarring and shifting problems. Used transmissions but are designed to provide a smooth and comfortable driving experience. We have a wide selection of transmissions available for purchase, so you can find the perfect one for your vehicle. Our team of experts is always available to help you find the right transmission for your needs. We pride ourselves on providing excellent customer service and quality products. Shop with us today and experience the difference!”",
    // "Gear up with a wide assortment of manual and automatic transmission to avoid jarring and shifting problems",
    link: "",
    image: transmissionImg,
    details: {
      title: "Neccessary Information",
      points: [
        "Check the flexplate for cracks or excessive wear on the starter teeth",
        "Replace oil pan gasket, front, and axle seals(if applicable)",
        "Swapp over any electrical wiring, sensors, valve bodies, linkage from the original transmission to the replacement",
        "Replace the transmission filter if it is an automatic(ATTENTION: There will be NO Filter or Fluid inside)",
        "Flush & inspect transmission cooling lines.Follow the manufacturer's recommendations",
        "Properly align torque converter(Automatic Transmission Only)",
        "Finish installation of the transmission.When finished, add transmission fluid to the manufacturer's specifications",
        "Start the engine and add more TF immediately.Additional fluid can now be added until the transmission reads ‘full’ on the dipstick",
        "Reset control module codes",
        "Adjust throttle position sensor cable",
      ],
      note: "",
    },
  },
  {
    title: "Axle",
    description:
      "“GetAutoMoto offers meticulously greased and well-maintained axles to help you tackle off-road and difficult terrain with ease. Our axles are designed to provide maximum traction and stability, so you can enjoy a smooth and comfortable ride. We have a wide selection of axles available for purchase, so you can find the perfect one for your vehicle. Our team of experts is always available to help you find the right axle for your needs. We pride ourselves on providing excellent customer service and quality products. Shop with us today and experience the difference!”",
    // "Now off road and difficult terrain travelling could be made a lot easier with our meticulously greased and well maintained Axles",
    link: "",
    image: axleImg,
    details: {
      title: "Neccessary Information",
      points: [
        "Replace seals and cover gasket (where applicable)",
        "Fill to the proper level using proper fluids in accordance with the vehicle manufacturer’s guidelines",
        "All tires on the vehicle must be the same size as recommended by the manufacturer for the vehicle",
        "None of the following are covered if included with assembly: hubs, bearings, brakes, steering linkage, brake lines, dust covers, seals or gaskets",
      ],
      note: "",
    },
  },
  {
    title: "Transfer Case",
    description:
      "“GetAutoMoto offers high-quality transfer case parts that are the most advanced and reliable parts in the market. Our transfer case parts can transform your ordinary vehicle into an extraordinary one. We have a wide selection of transfer case parts available for purchase, so you can find the perfect one for your vehicle. Our team of experts is always available to help you find the right transfer case part for your needs. We pride ourselves on providing excellent customer service and quality products. Shop with us today and experience the difference!”",
    // "Our high quality Transfer Case parts are the most advanced and reliable parts in the market that can transform your ordinary vehicle to extra ordinary",
    link: "",
    image: transferCaseImg,
    details: {
      title: "Neccessary Information",
      points: [
        "Replace seals and cover gasket(where applicable)",
        "Fill to the proper level using proper fluids in accordance with the vehicle manufacturer’s guidelines",
        "All tires on the vehicle must be the same size as recommended by the manufacturer for the vehicle",
        "Transfer any linkage, wiring harness, sensors, or electronic motors from original unit to the unit that was purchased",
      ],
      note: "",
    },
  },
];
export const HomePopularProductsData = [
  {
    title: "Light Asssemble",
    description:
      "Purchase high quality, Rebuilt, smooth running Engines, with lesser price and lower miles",
    link: "",
    image: lightImg,
  },
  {
    title: "Starter",
    description:
      "Purchase high quality, Rebuilt, smooth running Engines, with lesser price and lower miles",
    link: "",
    image: StarterImg,
  },
  {
    title: "Spindle Knuckle",
    description:
      "Purchase high quality, Rebuilt, smooth running Engines, with lesser price and lower miles",
    link: "",
    image: spindleImg,
  },
  {
    title: "Blower Motor",
    description:
      "Purchase high quality, Rebuilt, smooth running Engines, with lesser price and lower miles",
    link: "",
    image: blowerImg,
  },
  {
    title: "Electronic Control Module",
    description:
      "Purchase high quality, Rebuilt, smooth running Engines, with lesser price and lower miles",
    link: "",
    image: controlModuleImg,
  },
  {
    title: "Cylinder Head",
    description:
      "Purchase high quality, Rebuilt, smooth running Engines, with lesser price and lower miles",
    link: "",
    image: cylinderHeadImg,
  },
  {
    title: "Engine Assemble",
    description:
      "Purchase high quality, Rebuilt, smooth running Engines, with lesser price and lower miles",
    link: "",
    image: engineAssembleImg,
  },
  {
    title: "Window Regulator",
    description:
      "Purchase high quality, Rebuilt, smooth running Engines, with lesser price and lower miles",
    link: "",
    image: regulatorImg,
  },
  // {
  //     title: 'REPAIR PARTS',
  //     description: 'Purchase high quality, Rebuilt, smooth running Engines, with lesser price and lower miles',
  //     link: '',
  //     image: repairPartsImg,
  // },
  // {
  //     title: 'OTHER PARTS',
  //     description: 'Purchase high quality, Rebuilt, smooth running Engines, with lesser price and lower miles',
  //     link: '',
  //     image: greyBGImg,
  // },
];
export const ContactUsData = {
  Address:
    "2045 W Grand Ave \nChicago , IL \n60612-1577 \nHours: 09.00 am - 06.00 pm CST",
  EmailId: " sales@getautomoto.com",
  Telephone: "+1 (708) 575-8047",
  Mobile: "+1 (708) 575-8047",
};
export const AboutUSData = {
  General: {
    // title: "General Information",
    // subTitile: "SUCCESS STORY",
    content: {
      p1: "GetAutoMoto offers top-quality used OEM auto parts and accessories with unparalleled convenience and reliability. Based in Chicago, we have a vast network of suppliers throughout North America, making it easy for you to find the right part at an affordable price. We have a wide selection of authentic parts from over 5000 yards in the US and Canada, and our dedicated sales team is available 365 days a year to assist you in promptly shipping them to your preferred location.",
      // p2: "Dapibus lacinia dolor vel tinvidunt nisi vulputate quis nunc et feugiat diam, nulla mauris ac erat, hendrerit et feugiat et, gravida vel massa morbi sit amet sollicitudin nulla.",
      // p3: "Phasellus vitae ante purus. Phasellus porttitor ligula viverra sem tristique ullamcorper. eu vitae risus. Suspendisse aliquam volutpat augue, eu porta nibh sagittis non. Aliquam libero arcu, condimentum a metus id, pharetra scelerisque leo. Aenean varius, nulla eget maximus porttitor, augue orci",
    },
  },
  keyFeatures: {
    description:
      "Welcome to GetAutoMoto parts, your go-to destination for all things related to automobiles. We strive to bring you the latest automobile parts, all in one place. Our team of experts is always available to help you find the right part for your vehicle. We understand that every vehicle is unique, and we take the time to listen to your specific needs to ensure that you get the right part for your vehicle.",
    features: [
      {
        title: "RELIABILITY & SUSTAINABILITY",
        icon: "fa fa-line-chart",
        smallDescription:
          "Over 10 years of experience for quality and exceptional service",
      },
      {
        title: "FULL INTEGRITY",
        icon: "fa fa-bullseye",
        smallDescription: "Locate high-quality used OEM auto parts easily.",
      },
      {
        title: "GREAT SUPPORT",
        icon: "fa fa-fighter-jet",
        smallDescription:
          "Get great service from an experienced and knowledgeable team!",
      },
    ],
  },
};
export const ServicesData = {
  Services: [
    // {
    //     image: CertifiedServiceImg,
    //     title: "Certified Service",
    //     smallDescription: "Quuntur magni dolores eos qui ratione voluptatem sequi nesciunt. Neque porro quisquam est.",
    //     description: "Minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum. Sed ut perspiciatis unde omnis iste natus error sit.\n Voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo.Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt.Neque porro quisquam est, qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit, sed quia non numquam eius modi tempora incidunt ut labore et dolore magnam aliquam quaerat voluptatem.Aliquam bibendum lacus quis nulla dignissim faucibus.Sed mauris enim, bibendum at purus aliquet, maximus molestie tortor.Sed faucibus et tellus eu sollicitudin.Sed fringilla malesuada luctus.",
    //     title1: "AT YOUR HOME OR MECHANIC",
    //     title2: "WE SERVICE ALL MAJOR MAKES",
    //     details: {
    //         title: "Neccessary Information",
    //         points: [
    //             "Perform a full tune-up (spark-plugs, wires, air filters, hoses, belts)",
    //             "Replace engine oil to manufacturers suggested levels",
    //             "Replace oil filter",
    //             "Replace gaskets and swap as needed (intake and exhaust manifold, valve cover, oil pan & rear main seal)",
    //             "Inspect and replace(if needed) new timing belt/ chain",
    //             "Replace thermostat and water pump",
    //             "Flush the cooling system and refill with antifreeze to appropriate level",
    //             "Swap over original wiring harness with sensors",
    //             "Swap over or replace all bolt- on accessories(alternator, starter, AC compressor, etc)",
    //             "Change the oil after the first 600 miles and at recommended service intervals thereafter",
    //             "Service the vehicle at proper intervals as per manufacturer's guidelines",
    //         ],
    //         note: "The exhaust manifold will not be included in cases where the catalytic converter cannot be detached from it. \n * It is forbidden by law."
    //     }
    // },
    {
      image: QualityAUtoPartsImg,
      title: "High Quality OEM Parts",
      // smallDescription: "Quuntur magni dolores eos qui ratione voluptatem sequi nesciunt. Neque porro quisquam est.",
      description:
        "Minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum. Sed ut perspiciatis unde omnis iste natus error sit.\n Voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo.Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt.Neque porro quisquam est, qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit, sed quia non numquam eius modi tempora incidunt ut labore et dolore magnam aliquam quaerat voluptatem.Aliquam bibendum lacus quis nulla dignissim faucibus.Sed mauris enim, bibendum at purus aliquet, maximus molestie tortor.Sed faucibus et tellus eu sollicitudin.Sed fringilla malesuada luctus.",
      title1: "AUTO PARTS",
      title2: "WE ASSURE QUALITY PARTS",
    },
    {
      image: WarrantyIssuesHandelingImg,
      title: "Warranty Issues Handeling",
      // smallDescription: "Quuntur magni dolores eos qui ratione voluptatem sequi nesciunt. Neque porro quisquam est.",
      description:
        "Minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum. Sed ut perspiciatis unde omnis iste natus error sit.\n Voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo.Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt.Neque porro quisquam est, qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit, sed quia non numquam eius modi tempora incidunt ut labore et dolore magnam aliquam quaerat voluptatem.Aliquam bibendum lacus quis nulla dignissim faucibus.Sed mauris enim, bibendum at purus aliquet, maximus molestie tortor.Sed faucibus et tellus eu sollicitudin.Sed fringilla malesuada luctus.",
      title1: "WARRANTY",
      title2: "WE HANDLE ALL ISSUES",
    },
    //  {
    //     image: DealerPriceImg,
    //     title: "Dealer Price",
    //     smallDescription: "Quuntur magni dolores eos qui ratione voluptatem sequi nesciunt. Neque porro quisquam est.",
    //     description: "Minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum. Sed ut perspiciatis unde omnis iste natus error sit.\n Voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo.Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt.Neque porro quisquam est, qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit, sed quia non numquam eius modi tempora incidunt ut labore et dolore magnam aliquam quaerat voluptatem.Aliquam bibendum lacus quis nulla dignissim faucibus.Sed mauris enim, bibendum at purus aliquet, maximus molestie tortor.Sed faucibus et tellus eu sollicitudin.Sed fringilla malesuada luctus.",
    //     title1: "SAVE OVER 50% OFF",
    //     title2: "DEALER PRICES",
    // },
    {
      image: QuickQuotesImg,
      title: "Quick Quotes",
      // smallDescription: "Quuntur magni dolores eos qui ratione voluptatem sequi nesciunt. Neque porro quisquam est.",
      description:
        "Minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum. Sed ut perspiciatis unde omnis iste natus error sit.\n Voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo.Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt.Neque porro quisquam est, qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit, sed quia non numquam eius modi tempora incidunt ut labore et dolore magnam aliquam quaerat voluptatem.Aliquam bibendum lacus quis nulla dignissim faucibus.Sed mauris enim, bibendum at purus aliquet, maximus molestie tortor.Sed faucibus et tellus eu sollicitudin.Sed fringilla malesuada luctus.",
      title1: "QUICK QUOTES THAT SAVE",
      title2: "YOUR TIME",
    },
    {
      image: FastDeliveryImg,
      title: "Fast Delivery",
      // smallDescription: "Quuntur magni dolores eos qui ratione voluptatem sequi nesciunt. Neque porro quisquam est.",
      description:
        "Minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum. Sed ut perspiciatis unde omnis iste natus error sit.\n Voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo.Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt.Neque porro quisquam est, qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit, sed quia non numquam eius modi tempora incidunt ut labore et dolore magnam aliquam quaerat voluptatem.Aliquam bibendum lacus quis nulla dignissim faucibus.Sed mauris enim, bibendum at purus aliquet, maximus molestie tortor.Sed faucibus et tellus eu sollicitudin.Sed fringilla malesuada luctus.",
      title1: "FAST DELIVERY",
      title2: "HOME OR MECHANIC SHOP",
    },
    // {
    //     image: CertifiedMechaniciMG,
    //     title: "Certified Mechanic",
    //     smallDescription: "Quuntur magni dolores eos qui ratione voluptatem sequi nesciunt. Neque porro quisquam est, qui dolorem ipsum quiaolor sit amet, consectetur, adipisci velit, sed quia non numquam eius modi tempora incidunt ut labore et dolore magnam dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
    //     description: "Minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum. Sed ut perspiciatis unde omnis iste natus error sit.\n Voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo.Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt.Neque porro quisquam est, qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit, sed quia non numquam eius modi tempora incidunt ut labore et dolore magnam aliquam quaerat voluptatem.Aliquam bibendum lacus quis nulla dignissim faucibus.Sed mauris enim, bibendum at purus aliquet, maximus molestie tortor.Sed faucibus et tellus eu sollicitudin.Sed fringilla malesuada luctus.",
    //     title1: "AT YOUR HOME OR MECHANIC ",
    //     title2: "WE SERVICE ALL MAJOR MAKES",
    // },
  ],
};
export const PoliciesData = [
  // {
  //   title: "PRIVACY",
  //   description:
  //     "Get Auto Moto values your privacy and explains how we collect, use, and protect your personal information in our Privacy Policy. We recognize the importance of maintaining your privacy. We value our relationship with you, and we appreciate the trust you show in providing information to us. Therefore, it is of utmost importance to us to explain how we collect, use, and protect customer information.",
  //   content: [
  //     {
  //       header: "",
  //       description: "",
  //     },
  //   ],
  // },
  // {
  //   title: "RETURN POLICY",
  //   description:
  //     "If you receive a damaged or incorrect part, contact us immediately and wait for the return label before sending it back. Return the item within 7 business days to the address we provide with the RMA. We refund your payment once we receive the shipment, but labor costs aren't covered. We'll try to replace a defective part; otherwise, we'll refund the total sale amount. All returns should be received before the warranty expires, and return shipping costs won't be reimbursed. If you ordered the wrong part, returns may be subject to a 25% restocking fee, and shipping amounts may be withheld. Check with an expert before ordering. Items returned after the warranty period won't be refunded and will be returned to you. Parts must be received within 30 days of the original shipment delivery date to be eligible for a refund.",
  //   content: [
  //     {
  //       header: "",
  //       description: "",
  //     },
  //   ],
  // },
  {
    title: "WARRANTY POLICY",
    description: "",
    content: [
      {
        header: "ENGINE WARRANTY",
        description:
          "Our engine assemblies come as complete units with a warranty that covers only the long block. To ensure optimal engine performance, you may need to replace other parts such as the oil pan, timing cover, A/C compressor, alternator, starter, distributor, seals, gaskets, and fuel injection or carburetor. We don't guarantee a specific mileage, but we make sure the engine is in good condition before shipping it to you.",
      },
      {
        header: "ECM, BCM & TCM etc. WARRANTY",
        description:
          "We guarantee that the computers we provide have the correct manufacturer's part number. However, it's important to note that all modules may need to be re-programmed prior to installation. Additionally, please be aware that modules are covered under a replacement warranty only.",
      },
      {
        header: "TRANSMISSION WARRANTY",
        description:
          "Our transmission warranty ensures smooth shifting and good gear condition. For manual transmissions, the flywheel should be turned before installation and seals/gaskets should be replaced for longevity.",
      },
      {
        header: "DRIVE SHAFT,AXLE,SPINDLE KNUCKLE",
        description:
          "We guarantee that our driveshafts are in good condition, free of any dents or bends. However, it may be necessary to replace the 'U' joints or ball joints before installation to ensure optimal performance.",
      },
      {
        header: "BODY PARTS WARRANTY",
        description:
          "Our warranty policy for used body parts (e.g., Bumpers, Fenders, Hoods, Doors) does not guarantee pristine condition due to age. While we ensure they won't be heavily rusted, badly dented, or in poor condition, minor cosmetic defects may be present. Exact colours or shades are also not guaranteed.",
      },
      {
        header: "STEERING COLUMN WARRANTY",
        description:
          "Our steering column warranty covers straight condition without bends or dents, but not the functionality of electrical switches, steering wheels, and coupling shafts.",
      },
      {
        header: "CYLINDER HEAD WARRANTY",
        description:
          "We guarantee that the cylinder heads are free from cracks or warping. However, they may require re-machining.",
      },
      {
        header: "CARBURETORS WARRANTY",
        description:
          "Our warranty for carburetors applies only to units that are sold as rebuildable.",
      },
      {
        header: "STRUTS & LOWER CONTOL ARM WARRANTY",
        description:
          "We provide a warranty for the struts and lower control arms to ensure that they are straight and free from rust. However, we do not offer a warranty for the ball joints and bushings.",
      },
    ],
  },
  {
    title: "Shipping POLICY",
    description: "",
    content: [
      {
        header: "Order Shipment",
        description:
          "After ordering, you'll receive a confirmation email and your credit card will be pre-authorized. We'll check if the item is in stock and ready to ship. Delivery should take 5-7 days, but we're not responsible for shipping delays. If your order isn't delivered within 30 days, we'll cancel and refund it.",
      },
      {
        header: "Order Shipment Information",
        description:
          "Your order will be shipped within 5-7 business days of placing the order, once we have processed the payment. We will send you tracking information via email within 24-48 hours after your order has left the warehouse. If you do not receive tracking information within six business days, contact us at GetAutoMoto or call us at ( ) (Monday to Friday, 09.00 am - 06.00 pm CST).",
      },
      {
        header: "Damaged Items",
        description:
          "When your item(s) arrive, please inspect the packaging. If you notice any damage, please make a note of it when signing for delivery. If your item(s) do arrive damaged, please send photos to sales@getautomoto.com We will process a replacement or refund for you. Please refer to our Return Policy for more information on refunds.",
      },
      {
        header: "Delivery",
        description:
          "At Get Auto Moto, we offer a diverse range of delivery options to cater to our customers' various needs. Whether you're purchasing used, new, or professionally rebuilt car and truck parts, we have a delivery option that will suit your requirements.",
      },
    ],
  },
];
export const TermsAndConditionsData = [
  {
    title: "OUR SERVICES",
    description:
      "It is vital to note that the information given through the Services is not meant for universal distribution or usage in any jurisdiction or country that goes against the law. Any individual who chooses to use the Services from a different location does so at their own risk, ensuring compliance with local laws. The Services are not tailored to comply with industry-specific regulations, like HIPAA or FISMA. If your interactions with the Services fall under such laws, it is best to avoid using them. Additionally, it is crucial not to use the Services in a way that violates GLBA to prevent legal risks. Legal counsel should be consulted if there are any concerns regarding the legality of using the Services.",
    content: [
      {
        header: "",
        description: "",
      },
    ],
  },
  {
    title: "PRODUCTS",
    description:
      "We aim to provide accurate details of our products, but errors may occur. Product colors and availability may differ from the actual item. We reserve the right to terminate the sale of any product and change prices without notice. If you purchase our products, we will strive to ensure accuracy, but we may cancel orders due to incorrect information, and provide a refund or exchange option.",
    content: [
      {
        header: "",
        description: "",
      },
    ],
  },
  {
    title: "PURCHASES AND PAYMENT",
    description:
      "When making purchases through the Services, you agree to provide accurate and complete information, and to promptly update your account and payment information. Sales tax will be added as required, and prices may change at any time. You agree to pay all charges and authorize us to charge your chosen payment method for the amounts owed. We may correct pricing errors and refuse any order, or limit/cancel quantities purchased per person or order. We also reserve the right to limit or prohibit orders from dealers, resellers, or distributors.",
    content: [
      {
        header: "",
        description: "",
      },
    ],
  },
  {
    title: "PRIVACY POLICY",
    description:
      "These Legal Terms are in effect for as long as you use the Services. We may deny access or terminate your use of the Services at any time and without warning and delete any posted content. If we terminate or suspend your account, you are prohibited from creating a new one. We take data privacy and security seriously, and by using our Services, you agree to our Privacy Policy. If you access our Services from outside the United States, you consent to your data being transferred and processed in the United States.",
    content: [
      {
        header: "",
        description: "",
      },
    ],
  },
  {
    title: "RETURN & REFUND POLICY",
    description: `
    * All orders are subject to a non-refundable bank transaction fee of 3.5% plus 15 cents.
    * IF customers received damaged or incorrect part, please contact us immediately and wait for the return label before sending it back.
    Damaged parts must be reported to GetAutoMoto's customer care# or email within 24hrs of delivery with images to be eligible for replace or refund.
    Please return the item within 7 business days to the address we provide with the return authorization.
    We'll try to replace any defective or damaged parts; otherwise, we will initiate a refund after the part or item is delivered to the warehouse.
    * Parts or items may be eligible for return due to customers error or request, or upon approval. 
    customers must return the parts or items to the warehouse in their original condition and undamaged, 
    including all original materials supplied with the part or item or package. Customers are responsible for return shipping.
    A "restocking fee" of 25% of the billing amount or shipping fee, whichever is greater,will be deducted from the billing amount before refund.
    * The initiation of a replacement or refund does not occur until the parts or items have been returned to and received at the warehouse location.
    * Customers who have ordered incorrect parts or items after email or text confirmation are not eligible for Returns, Refund or Credit.
    * Customers who cancel an order after the card has been charged are subject to a restocking fee of 25% of the billing amount.
    * A standard warranty of 30 days is applicable, except for the parts or items mentioned in the call, receipt, email or text at the time of purchase.
    
    Note: Do not ship it back without GetAutoMoto’s approval or authorization.`,
    //"If you receive a damaged or incorrect part, contact us immediately and wait for the return label before sending it back. Return the item within 7 business days to the address we provide with the RMA. We refund your payment once we receive the part undamaged & in original condition, but labor/restocking costs aren't covered. We'll try to replace a defective part; otherwise, we'll refund the total sale amount. All returns should be received before the warranty expires, and return shipping costs won't be reimbursed. If you ordered the wrong part, returns may be subject to a 25% restocking fee, and shipping amounts may be withheld. Check with an expert before ordering. Items returned after the warranty period won't be refunded and will be returned to you. Parts must be received within 30 days of the original shipment delivery date to be eligible for a refund.",
    content: [
      {
        header: "",
        description: "",
      },
    ],
  },

  {
    title: "USER DATA",
    description:
      "We collect and store data that you provide to use the Services, as well as data related to your use of the Services. While we perform regular backups, you are responsible for all data you transmit or use with the Services. We are not liable for any loss or corruption of your data, and you waive any right to take legal action against us for such loss or corruption.",
    content: [
      {
        header: "",
        description: "",
      },
    ],
  },
  {
    title: "INDEMNIFICATION",
    description:
      "You agree to protect us from any claims made by a third party arising from your use of the Services. This includes any losses, damages, or expenses incurred, such as legal fees. If necessary, we reserve the right to assume control of the defence, and you must cooperate with us at your expense. We will inform you of any such claims.",
    content: [
      {
        header: "",
        description: "",
      },
    ],
  },
  {
    title: "LIMITATIONS OF LIABILITY",
    description:
      "We are not liable for any direct, indirect, consequential, exemplary, incidental, special, or punitive damages arising from your use of the Services. Our liability to you for any cause is limited to the amount paid by you to us in the previous one (1) month. Some laws may not allow limitations on implied warranties or the exclusion or limitation of certain damages, in which case some or all these limitations may not apply to you.",
    content: [
      {
        header: "",
        description: "",
      },
    ],
  },
  {
    title: "DISCLAIMER",
    description:
      'The services are provided "as-is" and "as-available." We do not guarantee the accuracy or completeness of the content, and we are not responsible for any personal injury, property damage, unauthorized access, interruption or cessation of transmission, bugs, viruses, or errors in the content. We do not endorse or assume responsibility for any products or services advertised by third parties. Please use caution and your best judgment when purchasing products or services.',
    content: [
      {
        header: "",
        description: "",
      },
    ],
  },
  {
    title: "CORRECTIONS",
    description:
      "The Services may contain information with typographical errors, inaccuracies, or omissions, such as descriptions, pricing, availability, and other details. We have the right to correct any errors, inaccuracies, or omissions and to modify or update the information on the Services without providing prior notice.",
    content: [
      {
        header: "",
        description: "",
      },
    ],
  },
  {
    title: "DISPUTE RESOLUTION",
    description:
      "If the parties cannot resolve a dispute informally, it will be resolved through binding arbitration under the rules of the American Arbitration Association, except for certain disputes. The arbitration can be conducted in person, by phone, or online, and any related lawsuit must be filed within one year. The parties agree to submit to personal jurisdiction in Cook County, Illinois, and disputes cannot be arbitrated on a class-action basis. The United Nations Convention on Contracts for the International Sale of Goods and the Uniform Computer Information Transaction Act are not applicable.",
    content: [
      {
        header: "",
        description: "",
      },
    ],
  },
  {
    title: "GOVERNING LAW",
    description:
      "These Legal Terms, as well as your use of the Services, are regulated and interpreted in accordance with the laws of the State of Illinois that apply to agreements made and performed entirely within the State of Illinois, without considering any principles of conflict of laws.",
    content: [
      {
        header: "",
        description: "",
      },
    ],
  },
  {
    title: "MODIFICATIONS AND INTERRUPTIONS LAW",
    description:
      "We can change, modify, or remove any part of the Services without notice. We may also modify or discontinue all or part of the Services without notice. We are not responsible for any interruption, delay, or error in accessing or using the Services, and we have no liability for any loss or damage caused by it. We reserve the right to suspend, discontinue, or modify the Services at any time without notice. We have no obligation to maintain or support the Services or provide any updates or releases related to the Services.",
    content: [
      {
        header: "",
        description: "",
      },
    ],
  },
  {
    title: "SERVICES MANAGEMENT",
    description:
      "We have the right to monitor the Services for violations of these Legal Terms and take action against any violators, including notifying law enforcement. We can restrict or deactivate Contributions, delete large files, and manage the Services to protect our rights and ensure proper operation.",
    content: [
      {
        header: "",
        description: "",
      },
    ],
  },
  {
    title: "CONTRIBUTION LICENSE",
    description:
      "By submitting feedback or suggestions, you agree that the online platform can use and distribute them without owing compensation. The user retains ownership of their contributions, including any associated intellectual or proprietary rights. Users are responsible for their contributions, and the platform is not liable for any statements made in them. Users absolve the platform of any responsibility and agree not to take legal action related to their contributions.",
    content: [
      {
        header: "",
        description: "",
      },
    ],
  },
  {
    title: "USER GENERATED CONTRIBUTIONS",
    description: `In today's digital age, social media and online platforms have become an essential part of our lives, providing users with the opportunity to create and share content with others. However, it's crucial to understand and adhere to the guidelines and policies of these platforms. The legal terms of a particular online service outline guidelines for creating and sharing content, including the need for user representation and warranties, necessary licenses, rights, consents, releases, and permissions, as well as the consequences of violating these guidelines. Users must ensure that their contributions are accurate, truthful, and do not infringe on the proprietary or privacy rights of any third party, and do not contain offensive or objectionable content. Adhering to these guidelines is essential to maintain a safe and respectful online community.`,
    content: [
      {
        header: "",
        description: "",
      },
    ],
  },
  {
    title: "PROHIBITED ACTIVITIES",
    description: `When using our Services, you must comply with our terms of use, which prohibit certain activities. These include using the Services for unauthorized commercial purposes, attempting to access sensitive account information, engaging in illegal activities, and disrupting the Services or networks connected to them. Violating these terms may result in termination of access and legal action. You also agree not to post or transmit any harmful, illegal, or objectionable content on the Services. By using our Services, you agree to follow these terms and conditions.`,
    content: [
      {
        header: "",
        description: "",
      },
    ],
  },
  {
    title: "USER REPRESENTATIONS",
    description: `By using our Services, you represent and warrant that you have the legal capacity to do so and agree to comply with our Legal Terms. You also confirm that you are of legal age in your jurisdiction of residence, will not access our Services using automated means, and will not engage in any illegal activities. Your use of our Services must comply with applicable laws and regulations. Providing inaccurate, incomplete, outdated, or false information may result in the suspension or termination of your account and the restriction of future use of our Services.`,
    content: [
      {
        header: "",
        description: "",
      },
    ],
  },
  {
    title: "OUR INTELLECTUAL PROPERTY",
    description: `We own or have the rights to all the intellectual property in our Services, including the Content and Marks, and they are protected by copyright, trademark laws, and other intellectual property rights. You can access the Services and download or print the Content for personal non-commercial use only. You must not copy, reproduce, sell, or exploit the Services or Content for commercial purposes without our prior written permission. By submitting any content to us, you assign all intellectual property rights to us and agree that we can use it for any lawful purpose. You are responsible for any content that you post or upload and must comply with our "PROHIBITED ACTIVITIES" section. Any breach of our Intellectual Property Rights will result in termination of your right to use our Services.`,
    content: [
      {
        header: "",
        description: "",
      },
    ],
  },
];
