import React from "react";
import FlexBox from "./FlexBox";
import LoaderCircle from "../Images/LoaderCircle.svg";
import Logo from "../Screens/Header/Logo";
const CustomLoading = ({ height, width, loaderImageFalse }) => {
  return (
    <>
      <style jsx="true">{`
        .loader {
          animation: spin 5s linear infinite;
          border-radius: 50%;
        }

        @keyframes spin {
          0% {
            transform: rotate(0deg);
          }
          100% {
            transform: rotate(360deg);
          }
        }
      `}</style>
      <FlexBox flexBoxStyles={{ position: "relative" }} direction="column">
        <div className="loader">
          <img
            src={LoaderCircle}
            alt="loading"
            height={height ? height : 200}
            width={width ? width : 200}
          />
        </div>
        {loaderImageFalse ? null : (
          <FlexBox
            height={150}
            width={150}
            borderRadius="50%"
            pTop={20}
            pBot={20}
            pRight={20}
            pLeft={20}
            flexBoxStyles={{
              // background:
              //   "linear-gradient(65.37deg, #5AC4FA 25.85%, #7864FB 64.72%, #7864FB 92.24%)",
              position: "absolute",
            }}
          >
            {/* <img
              src="../../src\logo.svg"
              alt="loader"
              height={22}
              width={141.26}
            /> */}
            <Logo />
          </FlexBox>
        )}
      </FlexBox>
    </>
  );
};

export default CustomLoading;
