import React, { useState } from "react";
import Header from "./Header/Header";
import { IndexHeader } from "../Components/UIComponent";
import { ProductDetailsModal } from "../Components/CustomModal";
import PartsShadowBox from "./home_screen/HomeComponents/PartsShadowBox";
import ProductsBlocks from "./home_screen/HomeComponents/ProductsBlocks";
import { AboutUsWhatWeDo } from "./about_us/AboutUsComponent";
import servicesImg from "../Components/UpdatedImages/ServicesCrop.png";
import { FRContainer, TColors } from "../Components/DefaultComponents";

import HomeImage2 from "../Components/UpdatedImages/BGImage.svg";

const Home = (props) => {
  const [open, setOpen] = useState(false);
  const [data, setData] = useState();
  const [modalType, setModalType] = useState();
  return (
    <div style={{ width: "100%", minHeight: "100vh" }}>
      <Header active="Home" />
      {/* <div style={{
                width: "100%", zIndex: -1, display: "flex", flex: 1
            }}>
            </div> */}
      {/* <Slideshow /> */}

      {/* <FRContainer style={{ justifyContent: "center", marginTop: 30 }} >
                <RequestContainer />
            </FRContainer> */}
      <div style={{ marginTop: 30 }}>
        <IndexHeader
          number="01"
          title1="Welcome to"
          title2="GetAutoMoto"
          titleFooter="We locate and supply used OEM auto parts for any vehicle."
        />
        {/*!-- TrustBox widget - Review Collector --*/}
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginBottom: 10,
          }}
          class="trustpilot-widget"
          data-locale="en-US"
          data-template-id="56278e9abfbbba0bdcd568bc"
          data-businessunit-id="65555483305130bc0e0d2344"
          data-style-height="52px" //52px
          data-style-width="100%" //100%
        >
          <a
            href="https://www.trustpilot.com/review/getautomoto.com"
            target="_blank"
            rel="noopener"
          >
            Review us on Trustpilot
          </a>
        </div>

        {/* <HomeContent1 /> */}

        {open && modalType === "ProductDetailsModal" ? (
          <ProductDetailsModal
            open={open}
            btnClose={() => setOpen(false)}
            passingData={data}
          />
        ) : null}
        <PartsShadowBox
          passData={(data) => setData(data)}
          openModal={(e) => setOpen(e)}
          modalType={(e) => setModalType(e)}
        />
        <IndexHeader
          number="02"
          title1="Most Popular"
          title2="PRODUCTS"
          titleFooter="Quality OEM used auto parts"
        />
        <ProductsBlocks />
        <IndexHeader
          number="03"
          title1="List of"
          title2="Services"
          titleFooter="Millions of quality used OEM parts"
        />
        {/* <HomeServices /> */}
        <AboutUsWhatWeDo />
        <img
          src={servicesImg}
          alt="load error"
          style={{
            width: "100%",
          }}
        />

        <IndexHeader
          number="04"
          title1="FEATURED"
          title2="BRANDS"
          titleFooter="Trusted by billions"
        />

        {/* <FRContainer style={{ backgroundColor: TColors.white, margin: "5vw", marginTop: 0, zIndex:1 }}>
                <img
                    src="https://www.globalcarsbrands.com/wp-content/uploads/2013/08/car-brands.jpg"
                    alt='load error' style={{ width: '100%', borderRadius: 25 }} />
            </FRContainer> */}

        <FRContainer style={{ backgroundColor: TColors.background }}>
          <img
            src={HomeImage2}
            alt="load error"
            style={{ width: "-webkit-fill-available" }}
          />
        </FRContainer>
      </div>
    </div>
  );
};

export default Home;
