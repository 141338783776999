import React, { useState } from "react";
import { FRContainer, TColors } from "../../Components/DefaultComponents";
import Typo from "../../Components/Typo";
import { MenuData } from "../../WebData/ContentData";
import { Link } from "react-router-dom";
import { ScrollTop } from "../../Components/FunctionComponent";
// import CustomColors from "../../GlobalComponents/CustomColors";

export const HeaderMenu = (props) => {
  const [isHovered, setHovered] = useState(false);
  const [child, setChild] = useState();
  return (
    <FRContainer>
      {MenuData.HeaderMenuData.map((e, i) => (
        <div
          key={i}
          onMouseEnter={(i) => {
            setHovered(true);
            setChild(e.title);
          }}
          onMouseLeave={() => setHovered(false)}
        >
          <Link
            to={e.link}
            onClick={() => ScrollTop()}
            style={{ textDecoration: "none" }}
          >
            <span
              style={{
                color:
                  isHovered && child === e.title
                    ? TColors.primary
                    : props.active === e.title
                    ? TColors.primary
                    : props.color
                    ? "grey"
                    :"grey",
                ...Typo.h6,
                marginRight: 20,
                textDecoration: "none",
              }}
            >
              {e.title}
            </span>
          </Link>
        </div>
      ))}
    </FRContainer>
  );
};
