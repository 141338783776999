import React from 'react'
import 'react-responsive-modal/styles.css';
import { Modal } from 'react-responsive-modal';
import { CloseButton } from './UIComponent';
import { FRContainer, FS, TColors } from './DefaultComponents';
import Typo from './Typo';
import { Ticon } from '../Screens/Header/HeaderRight';

const CustomModal = (props) => {

    return (
        <Modal
            open={props.open}
            closeIcon={
                <div>
                </div>
            }
            center
        >
            <CloseButton closeB={props.btnClose} style={{ position: "absolute", top: 10, right: 0 }} />

            <FRContainer style={{ display: "flex", flex: 1, flexDirection: 'column', paddingTop: 30, }}>
                {props.children}
            </FRContainer>
        </Modal >

    )
}


export default CustomModal

export const ProductDetailsModal = (props) => {
    const Data = props.passingData
    return (
        <CustomModal open={props.open} btnClose={props.btnClose}>
            <span style={{ ...Typo.h3, marginBottom: 10, color: TColors.blue, textTransform: 'uppercase' }}>{Data.title}</span>
            <img style={{ width: '100%' }} alt="not loaded" src={Data.image} />

            {Data.details && Data.details.title ?
                <span style={{ ...Typo.h5, marginBottom: 10, color: TColors.primary, fontWeight: 700, marginTop: 10 }}>{Data.details.title}</span>
                : null}
            {Data.details && Data.details.points.length ?
                <FRContainer style={{ flexDirection: 'column', alignItems: 'flex-start', flex: 1 }}>
                    {Data.details.points.map((e, i) =>
                        <FRContainer key={i} style={{ alignItems: 'flex-start' }}>
                            <Ticon iconName="fa fa-arrow-circle-right" style={{ fontSize: FS.p, color: TColors.black1, marginRight: 10 }} />
                            <span style={{ ...Typo.p, marginBottom: 10 }}>{e}</span>
                        </FRContainer>)}
                </FRContainer>
                : null}
            {Data.details && Data.details.note ?
                <span style={{ ...Typo.p, whiteSpace: "pre-wrap" }}> <span style={{ ...Typo.h5, color: TColors.primary, fontWeight: 700 }}>Note: </span> {Data.details.note}</span>
                : null}
        </CustomModal>
    )
}