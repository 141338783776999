import React, { useEffect, useState } from 'react'
import { Container, FRContainer, TColors } from '../../Components/DefaultComponents'
import { Link } from "react-router-dom";
import Logo from './Logo';
import { HeaderMenu } from './Menu';
// import MenuDrop from './MenuDrop';
import HeaderRight, { EmailComponent, Telephone, Ticon } from './HeaderRight';
import SS from '../../WebData/ScreenSize';
import MenuOpenScreen from './MenuOpenScreen';
import { ScrollTop } from '../../Components/FunctionComponent';
import { ContactUsData } from '../../WebData/ContentData';

const Header = (props) => {
    const [navbar, setNavbar] = useState(false)
    const [menuOpen, setMenuOpen] = useState(false)
    const [isMobile] = useState(SS().isMobile)
    const [isTab] = useState(SS().isTab)

    const ChangeBackground = () => {
        if (window.scrollY >= 80) {
            setNavbar(true)
        } else {
            setNavbar(false)
        }
        return window.removeEventListener('scroll', SS)
    }
    window.addEventListener("scroll", ChangeBackground)

    useEffect(() => {
        if (menuOpen) {
            ScrollTop()
        }
    }, [menuOpen])

    return (
        <div style={{ zIndex: 100 }}>
            {menuOpen ?
                <MenuOpenScreen closeB={() => setMenuOpen(false)} active={props.active} />
                :
                <div style={{
                    ...styles.mainWrapper,
                    backgroundColor: navbar ? TColors.white : "transparent",
                    // backgroundColor: navbar ? TColors.black1 : "transparent",
                }}>
                    <Container style={styles.subWrapper}>

                        <div style={{ marginRight: 20 }} onClick={() => ScrollTop()}>
                            <Link to="/" style={{ textDecoration: 'none' }}>
                                <Logo color={navbar} />
                            </Link>
                        </div>
                        <FRContainer style={{ flexDirection: isTab && 'row-reverse', flex: 1,  }}>
                            <div style={{
                                flex: 1,
                            }}>
                                <FRContainer style={{ flexDirection: isMobile || isTab ? 'row-reverse' : 'row', justifyContent: 'flex-start' }}>
                                    {isMobile || isTab ?
                                        <div
                                            style={{
                                                display: "flex",
                                                flexDirection: "row-reverse",
                                                flex: 1,
                                                justifyContent: window.innerWidth <= 550 ? "flex-start" : "space-between",
                                                whiteSpace: "pre",
                                                gap:10,
                                                alignItems:'baseline'
                                            }}
                                        >
                                            <Ticon
                                                iconName="fa fa-bars"
                                                onClick={() => setMenuOpen(true)}
                                                style={{ marginRight: 0, color: navbar ? TColors.blue :TColors.primary }}
                                            />
                                            {window.innerWidth >= 550 ?
                                                <Telephone />
                                                :
                                                <Ticon
                                                    iconName="fa fa-phone"
                                                    // iconLink='tel:+19999999999'
                                                    iconLink={"tel:" + ContactUsData.Mobile}
                                                    // style={{ marginRight: 25 }}
                                                />
                                            }
                                            {window.innerWidth >= 550 ?
                                                <EmailComponent />
                                                :
                                                <Ticon
                                                    iconName="fa fa-envelope"
                                                    // iconLink='tel:+19999999999'
                                                    iconLink={"tel:" + ContactUsData.EmailId}
                                                    // style={{ marginRight: 25 }}
                                                />
                                            }
                                            {/* <div style={{ display: "flex", whiteSpace: "pre" }}>
                                                <Telephone />
                                            </div> */}

                                        </div>
                                        :
                                        < >

                                            <HeaderMenu color={navbar} active={props.active} />
                                            {/* <MenuDrop active={props.active} /> */}
                                        </>}
                                </FRContainer>
                            </div>
                            <div style={{ flex: 1, display: isMobile && "none" }}>
                                <HeaderRight />
                            </div>
                        </FRContainer>

                    </Container>
                </div>
            }
        </div >
    )
}


const styles = {
    mainWrapper: {
        height: 'auto',
        width: "100%",
        display: 'flex',
        alignItems: "center",
        justifyContent: "center",
        position: "fixed",
        zIndex: 2,
        top: 0,
        paddingTop: "10px",
        paddingBottom: "10px"
    },
    subWrapper: {
        width: "95%",
        display: "flex",
        flexDirection: "row",
        alignItems: 'center',
    }
}
export default Header
