import React from 'react'
import { FRContainer } from '../../Components/DefaultComponents'
import { BannerUI,  IndexHeader } from '../../Components/UIComponent'
import Header from '../Header/Header'
import AddressComponent from './ContactUsComponents/AddressComponent'
import ContactForm from './ContactUsComponents/ContactForm'

const ContactUS = () => {
    return (
        <div style={{ width: '100%', minHeight: "100vh", }}>
            <Header active="Contact us" />
            <BannerUI active="Contact us" />

            <FRContainer style={{ flexWrap: "wrap", alignItems: 'stretch', }}>

                <FRContainer style={{ flexDirection: 'column', flex: 1, justifyContent: 'space-between', alignItems: 'stretch' }}>

                    <IndexHeader
                        number="01"
                        title1="STAY"
                        title2="IN TOUCH"
                    />
                    <AddressComponent />

                </FRContainer>

                <FRContainer style={{ flexDirection: 'column', flex: 1.5, alignItems: 'stretch' }}>

                    <IndexHeader
                        number="02"
                        title1="CONTACT US"
                        title2="RIGHT NOW"
                    />
                    <ContactForm />

                </FRContainer>

            </FRContainer>

            {/* <IndexHeader
                number="03"
                title1="LOCATE"
                title2="US"
            />
            <WhiteBackground /> */}

            {/* <IndexHeader
                number="03"
                title1="FIND"
                title2="YOUR ORDERS"
            />
            <FindYouOrders /> */}
        </div>
    )
}

export default ContactUS
