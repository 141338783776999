import { FS, TColors } from "./DefaultComponents"

const Typo = {
    h1: {
        fontSize: FS.h1,
         fontFamily: "system-ui",
        fontWeight: 700
    },
    h2: {
        fontSize: FS.h2,
        fontFamily: "system-ui",
        fontWeight: 900,
        fontStyle:'normal'
    },
    h3: {
        fontSize: FS.h3,
         fontFamily: "system-ui",
        fontWeight: 700
    },
    h4: {
        fontSize: FS.h4,
         fontFamily: "system-ui",
        fontWeight: 700
    },
    h5: {
        fontSize: FS.h5,
         fontFamily: "system-ui",
        fontWeight: 400
    },
    h6: {
        fontSize: FS.h6,
         fontFamily: "system-ui",
        fontWeight: 400
    },

    p: {
        fontSize: FS.p,
         fontFamily: "system-ui",
        fontWeight: 300
    },
    bgNumber: {
        // fontSize: window.innerWidth > 400 ? "21.429em" : "10em",
        fontSize: "clamp(15em, 40vw, 19em)",
        position: "absolute",
        color: TColors.textBackground,
        // fontFamily: 'FontAwesome',
        letterSpacing: '-2',
        fontWeight: 1000,

    },
    bgText: {
        // fontSize: window.innerWidth > 400 ? FS.hugeText : FS.h1,
        fontSize: "clamp(10px, 10vw, 70px)",
        letterSpacing: 0,
        fontWeight: 700,
        textTransform: 'uppercase',
        whiteSpace: 'pre',
    }
}
export default Typo