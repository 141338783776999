import React from 'react'
import {  TColors } from '../../Components/DefaultComponents'
import { CloseButton } from '../../Components/UIComponent'
import AllMenuListUI from './AllMenuListUI'
// import { IconsMenu, Telephone } from './HeaderRight'

const MenuOpenScreen = (props) => {
    return (
        <div style={styles.mainWrapper}>
            <CloseButton closeB={() => props.closeB(false)} />
            <div style={{ ...styles.mainWrapper, ...styles.subWrapper }}>
                <AllMenuListUI active={props.active} closeB={() => props.closeB(false)} />
                {/* <div style={{ marginTop: 20 }}>
                    <Telephone />
                    <div style={{ display: 'flex', marginTop: 10, paddingBottom: 20 }}>
                        <IconsMenu style={{ fontSize: FS.h1 }} />
                    </div>
                </div> */}
            </div>
        </div>
    )
}

const styles = {
    mainWrapper: { width: "100vw", height: "100vh", backgroundColor: TColors.background2, overflow: 'hidden' },
    subWrapper: {
        backgroundColor: 'transparent',
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-around",
        alignItems: 'left',
        padding: 20
    }
}
export default MenuOpenScreen