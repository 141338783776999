import React from 'react'
import { FRContainer } from '../../Components/DefaultComponents'
import { BannerUI, IndexHeader } from '../../Components/UIComponent'
import Header from '../Header/Header'
import { PartRequestFeatures, PartRequestForm } from './PartRequestComponents'

const PartRequest = () => {
    return (
        <div style={{ width: '100%', minHeight: "100%", }}>
            <Header active="Part Request" />
            <BannerUI active="Part Request" />

            <FRContainer style={{ flexWrap: "wrap", alignItems: 'stretch', }}>

                <FRContainer style={{ flexDirection: 'column', flex: 1, justifyContent: 'space-between' }}>

                    <IndexHeader
                        number="01"
                        title1="KEY"
                        title2="FEATURES"
                    />
                    <PartRequestFeatures />
                </FRContainer>

                <FRContainer style={{ flexDirection: 'column', flex: 2, alignItems: 'stretch' }}>


                    <IndexHeader
                        number="02"
                        title1="PART"
                        title2="REQUEST"
                    />
                    <PartRequestForm />
                </FRContainer>

            </FRContainer>
            {/* <IndexHeader
                number="03"
                title1="FIND"
                title2="YOUR ORDERS"
            />
            <FindYouOrders /> */}
        </div>
    )
}

export default PartRequest
