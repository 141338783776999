const CustomColors = {
  darkGrey: "#5F6165",
  bgColor1: "#F5F5F5",
  bgColor2: "#FAFAFA",
  bgColor3: "#766AFB",
  bgColor4: "rgba(118, 106, 251, 0.1)",
  bgColor5: "#FBFBFB",
  bgColor6:"#F6F6F9",
  bgColor7:'#F1F0FF',
  white: "#FFFFFF",
  updatedPrimary: "#0E65B0",
  // updatedPrimary: "#6154F3",
  primary: "#242731",
  black1: "#373C48",
  black2: "#434853",
  black3: "#455A64",
  black4:'#555555',
  lineColor: "#C7C7C7",
  lineColor2:'#8E8E8E',
  lineColor3:'#CBC7FD',
  lineColor4:'#C2C2C3',
  lightGreen:'#6EEA73',
  darkGreen: '#55D371',
  red:"#E25538",
  lightRed:" rgba(226, 85, 56, 0.1)",
  lineRed:"#E25538",
  primaryGradient: 'radial-gradient(92.17% 337.58% at 3.72% 115.36%, #62AAFA 0%, #7864FB 31.14%, #4741D7 100%)',
  gradient1: "linear-gradient(180deg, #7864FB 0%, #4343CE 100%)", 
  gradient2: "radial-gradient(82.75% 324.48% at 10.25% 71.78%, rgb(1, 163, 193) 0%, #E43518 88.62%)",
  grey:'#3D3D3D',
  blue:'#01A3C1',
  blue2: "#377DFF",
blue3:'#99A2BD'

};
export default CustomColors;
