import React from "react";
import { Link } from "react-router-dom";
import { FRContainer, FS, TColors } from "../../Components/DefaultComponents";
import { ScrollTop } from "../../Components/FunctionComponent";
import Typo from "../../Components/Typo";
import {
  ContactUsData,
  HomeContentData,
  MenuData,
} from "../../WebData/ContentData";
import { Ticon } from "../Header/HeaderRight";

const Footer = () => {
  return (
    <FRContainer style={styles.wrapper}>
      <FRContainer style={{ ...styles.wrapper, ...styles.subWrapper }}>
        <CompanySmallInfo />
        <FooterMenuList />
        <FooterContactUs />
      </FRContainer>
      <RightsReserved />
    </FRContainer>
  );
};

const FooterTitle = (props) => {
  return (
    <div style={{ marginBottom: "2.5em" }}>
      <span style={{ ...styles.FTitle, ...props.style }}>{props.title}</span>
    </div>
  );
};

const FooterP = (props) => {
  return (
    <div style={{ marginBottom: 10 }}>
      {!props.iconNo ? null : (
        <Ticon iconName="fa fa-angle-right" style={{ fontSize: FS.p }} />
      )}
      <span
        style={{ ...styles.FooterP, ...props.style, whiteSpace: "pre-wrap" }}
      >
        {props.content}
      </span>
    </div>
  );
};

const CompanySmallInfo = (props) => {
  return (
    <div style={{ ...styles.contentWrapper, flex: 2 }}>
      <FooterTitle title="GetAutoMoto" />
      <FooterP
        content={
          HomeContentData.welcomeContent.p1 +
          "\n" +
          HomeContentData.welcomeContent.p2
        }
        iconNo={false}
      />
      {/* <IconsMenu style={{ fontSize: FS.p, marginBottom: 10 }} /> */}
    </div>
  );
};

const FooterMenuList = (props) => {
  return (
    <div
      style={{
        ...styles.contentWrapper,
        whiteSpace: "nowrap",
      }}
    >
      <FooterTitle title="Sitemap" />
      {MenuData.AllMenuData.map((e, i) => (
        <Link
          to={e.link}
          key={i}
          onClick={() => ScrollTop()}
          style={{ textDecoration: "none" }}
        >
          <FooterP
            // iconNo={true}
            content={e.title}
          />
        </Link>
      ))}
    </div>
  );
};

const FooterContactUs = (props) => {
  return (
    <div style={{ ...styles.contentWrapper }}>
      <FooterTitle title="Contact Us" />
      <FRContainer style={{ justifyContent: "flex-start", marginBottom: 20 }}>
        <Ticon
          iconName="fa fa-map-marker"
          style={{ fontSize: FS.p, width: 15 }}
        />
        <span
          style={{ ...Typo.p, color: TColors.white, whiteSpace: "pre-wrap" }}
        >
          {ContactUsData.Address}
        </span>
      </FRContainer>
      <FRContainer style={{ justifyContent: "flex-start", marginBottom: 20 }}>
        <Ticon
          iconName="fa fa-envelope"
          style={{ fontSize: FS.p, width: 15 }}
        />
        <a
          href={"mailto:" + ContactUsData.EmailId}
          style={{ textDecoration: "none" }}
        >
          <span style={{ ...Typo.p, color: TColors.white }}>
            {ContactUsData.EmailId}
          </span>
        </a>
      </FRContainer>
      {/* <FRContainer style={{ justifyContent: "flex-start", marginBottom: 20 }}>
                <Ticon iconName="fa fa-phone" style={{ fontSize: FS.p, width: 15 }} />
                <span style={{ ...Typo.p, color: TColors.white }}>
                    {ContactUsData.Telephone}
                </span>
            </FRContainer> */}
      <FRContainer style={{ justifyContent: "flex-start", marginBottom: 10 }}>
        <Ticon iconName="fa fa-mobile" style={{ fontSize: FS.p, width: 15 }} />
        <span style={{ ...Typo.p, color: TColors.white }}>
          {ContactUsData.Mobile}
        </span>
      </FRContainer>
    </div>
  );
};

const RightsReserved = (props) => {
  return (
    <FRContainer style={styles.rightsWrapper}>
      <span style={{ ...Typo.h6, color: TColors.white, textAlign: "center" }}>
        Get Auto Moto: 2023 All Rights Reserved
      </span>
    </FRContainer>
  );
};

const styles = {
  wrapper: {
    flexWrap: "wrap",
    backgroundColor: TColors.black1,
    width: "100%",
  },
  subWrapper: {
    // backgroundColor: 'red',
    maxWidth: "93vw",
    minWidth: "50vw",
    // marginTop: '15vh',
    // marginBottom: '15vh',
    alignItems: "stretch",
    height: "webkit-fill-available",
  },
  FTitle: {
    ...Typo.h3,
    color: TColors.primary,
    whiteSpace: "nowrap",
  },
  FooterP: {
    ...Typo.p,
    color: TColors.white,
    textDecoration: "none",
  },
  contentWrapper: {
    flex: 1,
    paddingRight: 20,
    borderRightStyle: "solid",
    borderBottomStyle: "outset",
    margin: 20,
    maxWidth: "93vw",
  },
  rightsWrapper: {
    marginBottom: 50,
    marginTop: 50,
    width: "100%",
  },
};

export default Footer;
