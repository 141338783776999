import React from "react";

export const TColors = {
  black1: "#232323",
  blue: "rgb(8, 155, 255)",
  primary: "#E43518",
  secondary: "#000000",
  white: "#FFFFFF",
  background: "#EAEAEA",
  background2: "#060B26",
  grey: "#F0F0F0",
  textBackground: "#DCDCDC",
};

export const Container = (props) => {
  return <div style={props.style}>{props.children}</div>;
};
export const FRContainer = (props) => {
  return (
    <div
      ref={props.ref}
      onClick={() => props.onClick && props.onClick()}
      style={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        //whiteSpace:"pre-wrap",
        alignItems: "left", //before "center"
        ...props.style,
      }}
    >
      {props.children}
    </div>
  );
};
export const Text = (props) => {
  return <span style={props.style}>{props.children}</span>;
};

export const FS = {
  h1: "clamp(18px, 2.625em, 2.625em)",
  h2: "clamp(16px, 2.25em, 2.25em)",
  h3: "clamp(14px, 1.75em, 1.75em)",
  h4: "clamp(12px, 1.5em, 1.5em)",
  h5: "clamp(10px, 1.375em, 1.375em)",
  h6: "clamp(8px, 1.125em, 1.125em)",
  h7: "clamp(6px, 1.125em, 1.125em)",
  p: "clamp(6px, 1em, 1em)",
  hugeText: "clamp(30px, 5.714emem, 5.714emem)",
};
// export const FS = {
//     h1: "2.625em",
//     h2: "2.25em",
//     h3: "1.75em",
//     h4: "1.5em",
//     h5: "1.375em",
//     h6: "1.125em",
//     hugeText: "5.714em"
// }
