import React from 'react'
import { FRContainer, TColors } from '../../../Components/DefaultComponents'
import Typo from '../../../Components/Typo'
import { HomePopularProductsData } from '../../../WebData/ContentData'

const ProductsBlocks = () => {
    return (
        <FRContainer style={{ width: '100%', flexWrap: 'wrap' }}>
            {HomePopularProductsData.map((e, i) =>
                <div key={i} style={{
                    ...styles.imageContainer,
                    'backgroundImage': `url(${e.image})`
                }}>
                    <span style={styles.title}>{e.title}</span>
                </div>
            )}
        </FRContainer>
    )
}

const styles = {
    imageContainer: {
        height: 250,
        width: '20%',
        minWidth: 300,
        backgroundColor: 'rgba(0,0,0,.3)',
        backgroundBlendMode: "darken",
        font: 'menu',
        transition: 'all 0.3s ease',
        // cursor: "pointer",
        // transform: 'translate3d(0px, 0px, 0.01px)',
        transformOrigin: "50% 50%",
        transformStyle: "flat",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        backgroundRepeat: "no-repeat",
        backgroundSize: 'cover',
        backgroundPosition: "center",
    },
    title: {
        ...Typo.h3,
        color: TColors.white,
        textAlign: 'center',
    }
}

export default ProductsBlocks
