import React from 'react'
import { TColors } from '../../Components/DefaultComponents'
import { BannerUI } from '../../Components/UIComponent'
import Header from '../Header/Header'
import { PoliciesTextContainer } from './PoliciesComponents/PoliciesComponents'

const Policies = () => {
    return (
        <div style={{ width: '100%', backgroundColor: TColors.background }}>
            <Header active="Policies" />
            <BannerUI active="Policies" />
            <PoliciesTextContainer />

        </div>
    )
}

export default Policies
