import React, { useState } from 'react'
import { MenuData } from '../../WebData/ContentData'
import { Link } from 'react-router-dom';
import { TColors } from '../../Components/DefaultComponents';
import Typo from '../../Components/Typo';

const AllMenuListUI = (props) => {
    const [isHovered, setHovered] = useState(false);
    const [child, setChild] = useState();
    return (
        <div style={{ display: 'flex', maxHeight: '60vh', flexDirection: 'column', flexWrap: 'wrap' }}>
            {MenuData.AllMenuData.map((e, i) =>
                <div
                    key={i}
                    onMouseEnter={(i) => { setHovered(true); setChild(e.title) }}
                    // onMouseLeave={() => setHovered(false)}
                    style={{ paddingBottom: 10, felx: 1 }}
                >
                    <Link to={e.link} style={{ textDecoration: 'none' }}>
                        <span
                            onClick={() => props.closeB(false)}
                            style={{
                                color: isHovered && child === e.title ? TColors.primary : props.active === e.title ? TColors.primary : props.color ? TColors.white : TColors.white,
                                ...Typo.h5,
                            }}> {e.title}</span>
                    </Link>

                </div>
            )}

        </div>
    )
}

export default AllMenuListUI
