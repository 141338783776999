
const SS = () => {
    let WindowWidth = window.innerWidth
    let isMobile = WindowWidth && WindowWidth <= 700 ? true : false
    let isTab = WindowWidth && WindowWidth > 700 && WindowWidth <= 1100 ? true : false
    let smallScreen = WindowWidth && WindowWidth <= 365 ? true : false


    return { isMobile: isMobile, isTab: isTab, smallScreen: smallScreen }
}

export default SS
