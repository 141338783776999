import React from "react";
import "./App.css";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

import home from "./Screens/";
import aboutus from "./Screens/about_us";
import policies from "./Screens/Policies";
import partlist from "./Screens/part_list";
import partrequest from "./Screens/part_request";
import contactus from "./Screens/contact_us";
import Footer from "./Screens/Footer/Footer";
import CustomColors from "./GlobalComponents/CustomColors";
import nopage from "./Screens/404";
import terms from "./Screens/Policies/Terms";
import customerupload from "./Screens/image_upload/CustomerUpload";
import yardupload from "./Screens/image_upload/YardUpload";
import FlexBox from "./GlobalComponents/FlexBox";
import HomeImage2 from "../src/Components/UpdatedImages/BGImage.svg";

export const App = () => {
  return (
    <div style={styles.webWrapper}>
      <Router>
        <Switch>
          <Route exact path="/" component={home} />
          <Route exact path="/aboutus" component={aboutus} />
          <Route exact path="/policies" component={policies} />
          <Route exact path="/terms" component={terms} />
          <Route exact path="/partlist" component={partlist} />
          <Route exact path="/partrequest" component={partrequest} />
          <Route exact path="/contactus" component={contactus} />
          <Route
            exact
            path="/customer/:id/:rid/:name"
            component={customerupload}
          />
          <Route
            exact
            path="/yard/:pid/:id/:rid/:name/:salesrep"
            component={yardupload}
          />
          <Route exact path="*" component={nopage} />
        </Switch>
        {window.location.pathname.includes("/customer") ||
        window.location.pathname.includes("/yard") ? (
          <FlexBox width={"-webkit-fill-available"}>
            <img src={HomeImage2} alt="load error" width="100%" />
          </FlexBox>
        ) : (
          <Footer />
        )}
      </Router>
    </div>
  );
};

const styles = {
  webWrapper: {
    minHeight: "100vh",
    width: "100%",
    margin: 0,
    // backgroundColor: TColors.background,
    background: CustomColors.white,
  },
};
export default App;
