import React from 'react'
import { BannerUI } from '../../Components/UIComponent'
import Header from '../Header/Header'

const PartList = () => {
    return (
        <div style={{ width: '100%', height: "100vh", }}>
            <Header active="Part List" />
            <BannerUI active="Part List" />

        </div>
    )
}

export default PartList
