import React, { useState } from "react";
import {
  FRContainer,
  FS,
  TColors,
  Text,
} from "../../Components/DefaultComponents";
import Typo from "../../Components/Typo";
import { ContactUsData, MenuData } from "../../WebData/ContentData";
import SS from "../../WebData/ScreenSize";

const HeaderRight = () => {
  const [isTab] = useState(SS() && SS().isTab);
  const [isMobile] = useState(SS() && SS().isMobile);

  return (
    <FRContainer
      style={{
        alignItems: "center",
        justifyContent: isTab ? "flex-end" : "flex-end",
      }}
    >
      <div style={{ display: "flex", whiteSpace: "pre", marginRight: 25 }}>
        {isMobile || isTab ? null : <EmailComponent />}
       
      </div>
      <div style={{ display: "flex", whiteSpace: "pre", marginRight: 25 }}>
      {isMobile || isTab ? null : <Telephone />}
       
      </div>
      <div>
        {/* {
                    // isTab ?
                    // <div style={{ marginLeft: isTab && 30 }}>
                    //     <i
                    //         className="fa fa-chevron-circle-down"
                    //         aria-hidden="true"
                    //         style={styles.iconStyle}
                    //     ></i>
                    // </div>
                    // :
                    isMobile || isTab ? null
                        :
                        <IconsMenu />
                } */}
      </div>
    </FRContainer>
  );
};

export const Telephone = () => {
  return (
    <>
      <a
        href={"tel:" + ContactUsData.Mobile}
        // href="tel:+19999999999"
        style={{ textDecoration: "none", color: TColors.blue }}
      >
        <i
          className="fa fa-phone"
          style={styles.iconStyle}
          aria-hidden="true"
        ></i>
        <Text style={{ ...Typo.h4, color: TColors.blue }}>
          {ContactUsData.Mobile}
        </Text>
      </a>
    </>
  );
};
{
  /* <FRContainer style={{ justifyContent: "flex-start", marginBottom: 20 }}>
<Ticon
    iconName="fa fa-envelope-o"
    style={{ ...styles.icon, fontSize: FS.h5 }}
/>
<a
    href={"mailto:" + ContactUsData.EmailId}
    style={{ textDecoration: "none" }}>
    <span style={{ ...Typo.h5, color: TColors.black1 }}>
        {ContactUsData.EmailId}
    </span>
</a>
</FRContainer> */
}
export const EmailComponent = () => {
  return (
    <>
      <a
        href={"mailto:" + ContactUsData.EmailId}
        // href="tel:+19999999999"
        style={{ textDecoration: "none", color: TColors.blue }}
      >
        <i
          className="fa fa-envelope"
          style={styles.iconStyle}
          aria-hidden="true"
        ></i>
        <Text style={{ ...Typo.h4, color: TColors.blue }}>
          {ContactUsData.EmailId}
        </Text>
      </a>
    </>
  );
};
export const IconsMenu = (props) => {
  return (
    <>
      {MenuData.SocialIcons.map((e, i) => (
        <span key={i}>
          <a href={e.iconLink} style={{ textDecoration: "none" }}>
            <i
              className={e.iconName}
              style={{
                ...styles.iconStyle,
                ...props.style,
                marginRight: i === MenuData.SocialIcons.length - 1 ? 0 : 25,
              }}
              aria-hidden="true"
            ></i>
          </a>
        </span>
      ))}
    </>
  );
};
export const Ticon = (props) => {
  return (
    <a href={props.iconLink} onClick={props.onClick}>
      <i
        className={props.iconName}
        style={{ ...styles.iconStyle, ...props.style }}
        aria-hidden="true"
      ></i>
    </a>
  );
};
const styles = {
  iconStyle: {
    // marginRight: '25px',
    fontSize: FS.h4,
    color: TColors.blue,
    textDecoration: "none",
  },
};

export default HeaderRight;
