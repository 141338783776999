import React from 'react'

const CustomText = ({
  children,
  fWeight,
  fSize,
  pLeft,
  txtColor,
  pRight,
  pBot,
  pTop,
  cursor,
  textAlign,
  fStyle,
  float,
  maxWidth,
  styles,
}) => {
  return (
    <span
      style={{
        fontFamily: "Poppins",
        fontStyle: fStyle ? fStyle : "normal",
        fontWeight: fWeight,
        fontSize: fSize,
        paddingLeft: pLeft,
        color: txtColor,
        paddingRight: pRight,
        paddingTop: pTop,
        paddingBottom: pBot,
        cursor: cursor,
        textAlign: textAlign,
        float: float,
        maxWidth: maxWidth,
        ...styles,
      }}
    >
      {children}
    </span>
  );
};

export default CustomText
