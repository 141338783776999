import React, { useState } from "react";
import FlexBox from "../../GlobalComponents/FlexBox";
import CustomColors from "../../GlobalComponents/CustomColors";
import Logo from "../Header/Logo";
import CustomText from "../../GlobalComponents/CustomText";
import CustomLoading from "../../GlobalComponents/CustomLoading";
import DeleteIcon from "../../Images/Delete.svg";
import { CustomButton } from "../../Components/UIComponent";
import CustomModal from "../../Components/CustomModal";

const UploadContainer = ({ type, id, name, pid, salesrep, rid }) => {
  const [selectedImage, setSelectedImage] = useState([]);
  const [notes, setNotes] = useState("");
  const [apiLoading, setApiLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [successMessage, setSuccessMessage] = useState("acda");
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const EmptyState = () => {
    setApiLoading(false);
    setNotes("");
    setSelectedImage([]);
  };
  const UploadCustAPICall = async () => {
    setApiLoading(true);
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      OrderID: id,
      CustomerImages: type === "cust" ? selectedImage : [],
      ReturnID: rid,
      CustComments: notes
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(
      `${process.env.REACT_APP_PUBLIC_UPLOAD_URL}customerUpload`,
      requestOptions
    )
      .then(
        async (response) =>
          // {
          //   const text = await response.text();

          //   if (response.ok) {
          response.json()
        //   } else {
        //     //   alert("Something went wrong, please call us back if the error persists");
        //     throw { status: response.status, message: text };
        //   }
        // }
      )
      .then((result) => {
        console.log(result);
        setSuccess(true);
        setSuccessMessage("You have successfully uploaded images.");
        EmptyState();
        return setApiLoading(false);
      })
      .catch((error) => {
        console.log("error", JSON.stringify(error.message));
        setError(true);
        setErrorMessage(error.message);
        return setApiLoading(false);
      });
  };
  const UploadYardAPICall = async () => {
    setApiLoading(true);
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      OrderID: id,
      POID: pid,
      YardImages: selectedImage,
      ReturnID: rid,
      YardComments: notes

    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(
      `${process.env.REACT_APP_PUBLIC_UPLOAD_URL}yardUpload`,
      requestOptions
    )
      .then(
        async (response) =>
          // {
          //   const text = await response.text();

          //   if (response.ok) {
          //     return
          response.json()
        //   } else {
        //     //   alert("Something went wrong, please call us back if the error persists");
        //     throw { status: response.status, message: text };
        //   }
        // }
      )
      .then((result) => {
        console.log(result);
        setSuccess(true);
        setSuccessMessage("You have successfully uploaded images.");
        EmptyState();
        return setApiLoading(false);
      })
      .catch((error) => {
        console.log("error", JSON.stringify(error.message));
        setError(true);
        setErrorMessage(error.message);
        return setApiLoading(false);
      });
  };
  return (
    <>
      {success || error ? null : (
        <FlexBox
          flexBoxStyles={{ border: `1px solid ${CustomColors.lineColor}` }}
          borderRadius={8}
          pTop={40}
          pBot={40}
          pLeft={40}
          pRight={40}
          direction={"column"}
          gap={30}
          mTop={50}
          mBot={50}
          //   width={"50vw"}
        >
          <Logo color={true} />
          {apiLoading ? (
            <CustomLoading loaderImageFalse height={100} width={100} />
          ) : (
            <>
              <FlexBox
                direction={"column"}
                width={"-webkit-fill-available"}
                ai="stretch"
              >
                {pid ? (
                  <UploadTextFieldElement title="Purchase Order Id :" content={pid} />
                ) : null}
                <UploadTextFieldElement title="Order Id :" content={id} />
                <UploadTextFieldElement title="Name :" content={name} />
                {salesrep ? (
                  <UploadTextFieldElement
                    title="Sales Representative :"
                    content={salesrep}
                  />
                ) : null}
              </FlexBox>
              <UploadImageContainer
                selectedImage={selectedImage}
                setSelectedImage={setSelectedImage}
              />
              <FlexBox
                gap={10}
                jc="flex-start"
                width={"-webkit-fill-available"}
              >
                <textarea
                  title="Notes"
                  placeholder={"Enter your comments in 100 characters"}
                  value={notes}
                  onChange={(e) => {
                    setNotes(e.target.value);
                  }}
                  color={CustomColors.black1}
                  style={{
                    height: 100,
                    width: "-webkit-fill-available",
                    fontSize: 14,
                    fontFamily: "system-ui",
                  }}
                  maxLength={100}
                />
              </FlexBox>
              <CustomButton
                btnClick={
                  type === "cust"
                    ? UploadCustAPICall
                    : type === "yard"
                    ? UploadYardAPICall
                    : console.log("no type found")
                }
                clicked={selectedImage && selectedImage.length ? true : false}
                btnText={"Submit"}
                disable={selectedImage && selectedImage.length ? false : true}
              >
                Submit
              </CustomButton>
            </>
          )}
        </FlexBox>
      )}
      {success ? (
        <CustomModal
          open={success}
          btnClose={() => {
            setSuccess(false);
            setSuccessMessage("");
          }}
        >
          <FlexBox
            minWidth={"20vw"}
            minHeight={"20vh"}
            pTop={10}
            direction={"column"}
            gap={10}
            ai="flex-start"
            jc="start"
          >
            <CustomText>Success : </CustomText>
            <CustomText>{successMessage}</CustomText>
          </FlexBox>
        </CustomModal>
      ) : null}
      {error ? (
        <CustomModal
          open={error}
          btnClose={() => {
            setError(false);
            setErrorMessage("");
          }}
        >
          <FlexBox
            minWidth={"20vw"}
            minHeight={"20vh"}
            pTop={10}
            direction={"column"}
            gap={10}
            ai="flex-start"
            jc="start"
          >
            <CustomText>Error : </CustomText>
            <CustomText>{errorMessage}</CustomText>
            <CustomText>
              Please try again, if persists please contact us with error message
            </CustomText>
          </FlexBox>
        </CustomModal>
      ) : null}
    </>
  );
};

export default UploadContainer;

const UploadTextFieldElement = ({ content, title }) => {
  return (
    <FlexBox gap={10} jc="flex-start">
      <CustomText>{title}</CustomText>
      <CustomText>{content}</CustomText>
    </FlexBox>
  );
};

const UploadImageContainer = ({ setSelectedImage, selectedImage }) => {
  const BytesToMB = (bytes) => {
    return (bytes / (1024 * 1024)).toFixed(2);
  };
  const GetTotalSize = () => {
    let totalSize = 0;

    selectedImage.forEach((image) => {
      totalSize += image.size;
    });

    return BytesToMB(totalSize);
  };

  return (
    <FlexBox width={"-webkit-fill-available"} direction={"column"} gap={10}>
      {selectedImage && selectedImage.length ? (
        <FlexBox
          width={"-webkit-fill-available"}
          gap={20}
          flexWrap={"wrap"}
          jc="flex-start"
          flexBoxStyles={{ border: `1px solid ${CustomColors.black1}` }}
          pTop={10}
          pBot={10}
          pLeft={10}
          pRight={10}
          borderRadius={10}
        >
          {selectedImage.map((e, i) => (
            <FlexBox gap={5} key={i}>
              <img
                alt="not found"
                width={50}
                height={50}
                src={URL.createObjectURL(e)}
              />
              <br />
              <FlexBox
                onClick={() =>
                  setSelectedImage(selectedImage.filter((f) => f !== e))
                }
              >
                <img alt="delete" src={DeleteIcon} height={12} width={12} />
              </FlexBox>
              <FlexBox
                height={40}
                width={1}
                bgColor={CustomColors.lineColor4}
              />
            </FlexBox>
          ))}
        </FlexBox>
      ) : null}

      <FlexBox
        width={"-webkit-fill-available"}
        flexBoxStyles={{ border: `1px solid ${CustomColors.lineColor2}` }}
        borderRadius={6}
        direction={"columns"}
      >
        <input
          style={{ width: "-webkit-fill-available", padding: 10 }}
          type="file"
          name="myImage"
          accept="image/*"
          onChange={(event) => {
            JSON.parse(BytesToMB(event.target.files[0].size)) +
              JSON.parse(GetTotalSize()) <=
              5 &&
            selectedImage &&
            selectedImage.length < 5
              ? setSelectedImage([...selectedImage, event.target.files[0]])
              : alert("Maximum upload limit exceeded");
          }}
        />
      </FlexBox>
      <CustomText fSize={12} fWeight={500} txtColor={CustomColors.black4}>
        You can upload upto 5 Images / 5 MB. Remaining {" "}
        {5 - JSON.parse(GetTotalSize())} MB
      </CustomText>
    </FlexBox>
  );
};
