import React from "react";
import FlexBox from "../../GlobalComponents/FlexBox";
import { useParams, } from "react-router";
import CustomColors from "../../GlobalComponents/CustomColors";
import UploadContainer from "./UploadContainer";

const CustomerUpload = () => {
  let { id, name, rid } = useParams();
  return (
    <FlexBox
      minHeight={200}
      width={"-webkit-fill-available"}
      bgColor={CustomColors.white}
      pLeft={30}
      pRight={30}
    >
      {id && name ? <UploadContainer type="cust" id={id} name={name} rid={rid}/> : null}
     
    </FlexBox>
  );
};

export default CustomerUpload;
