import React from "react";
import FlexBox from "../../GlobalComponents/FlexBox";
import { useParams } from "react-router";
import CustomColors from "../../GlobalComponents/CustomColors";
import UploadContainer from "./UploadContainer";

const YardUpload = () => {
  let { id, name, pid, salesrep, rid } = useParams();
  return (
    <FlexBox
      minHeight={200}
      width={"-webkit-fill-available"}
      bgColor={CustomColors.white}
      pLeft={30}
      pRight={30}
    >
      {id && name ? (
        <UploadContainer
          type="yard"
          id={id}
          name={name}
          pid={pid}
          salesrep={salesrep}
          rid={rid}
        />
      ) : null}
    </FlexBox>
  );
};

export default YardUpload;
