import React from "react";
import { FRContainer, TColors } from "../../../Components/DefaultComponents";
import Typo from "../../../Components/Typo";
import { WhiteBackground } from "../../../Components/UIComponent";
import {
  PoliciesData,
  TermsAndConditionsData,
} from "../../../WebData/ContentData";
// import SS from '../../../WebData/ScreenSize'

export const PoliciesTextContainer = ({ terms }) => {
  const Data = terms ? TermsAndConditionsData : PoliciesData;
  // const [isTab] = useState(SS().isTab)

  return (
    <FRContainer style={{ flexDirection: "column", padding: "1em" }}>
      {Data.map((e, i) => (
        <WhiteBackground
          key={i}
          style={{ marginBottom: i !== Data.length - 1 ? "1vh" : 0 }}
        >
          <FRContainer
            style={{
              flexDirection: "column",
              alignItems: "flex-start",
              marginBottom: "1.5em",
            }}
          >
            <span
              style={{
                ...Typo.h3,
                color: TColors.blue,
                textTransform: "uppercase",
                marginBottom: "0.4em",
                overflowWrap: "breakWord",
              }}
            >
              {e.title}
            </span>

            {e.description ? (
              <span
                style={{
                  ...Typo.p,
                  color: TColors.black1,
                  marginBottom: "0.4em",
                  whiteSpace: "pre-line",
                }}
              >
                {e.description}
              </span>
            ) : null}

            {e.content
              ? e.content.map((e, i) => (
                  <FRContainer
                    key={i}
                    style={{
                      alignItems: "flex-start",
                      flexDirection: "column",
                    }}
                  >
                    <span
                      style={{
                        ...Typo.h5,
                        fontWeight: 500,
                        color: TColors.primary,

                        marginBottom: "0.4em",
                      }}
                    >
                      {e.header}
                    </span>
                    <span
                      style={{
                        ...Typo.p,
                        color: TColors.black1,
                        marginBottom: "1em",
                        //whiteSpace: "pre-line",
                      }}
                    >
                      {e.description}
                    </span>
                  </FRContainer>
                ))
              : null}
          </FRContainer>
        </WhiteBackground>
      ))}
    </FRContainer>
  );
};
