import React from 'react'
import { FRContainer, FS, TColors } from '../../../Components/DefaultComponents'
import Typo from '../../../Components/Typo'
import { HoemWelcomeBannerData } from '../../../WebData/ContentData'
import SS from '../../../WebData/ScreenSize'

const PartsShadowBox = (props) => {
    // const [clicked, setClicked] = useState(true)
    // const [item, setItem] = useState("")
    // const ClickFunc = (e) => {
    //     setClicked(e.title === item ? !clicked : true)
    //     setItem(e.title)
    //     props.passData(e)
    //     props.openModal(true)
    //     props.modalType("ProductDetailsModal")
    // }
    return (
        <div style={styles.mainWrapper}>
            {HoemWelcomeBannerData.map((e, i) =>
                <FRContainer
                    key={i}
                    style={{ flex: 1, flexDirection: i % 2 ? 'row' : 'row-reverse', }}
                    // onClick={() => ClickFunc(e)}
                >
                    <div
                        style={{
                            ...styles.contentWrapper,
                            'backgroundImage': `url(${e.image})`,
                        }}>
                    </div>

                    <div
                        key={i}
                        style={{
                            ...styles.contentWrapper,
                            flex: 1,
                            flexDirection: 'column',

                        }}>
                        <span style={{
                            ...Typo.h1,
                            color: TColors.blue,
                            textTransform: 'uppercase',
                            fontSize: SS().isMobile ? FS.h3 : SS().isTab ? FS.h4 : FS.h3,
                            // wordBreak: "break-word"
                            textAlign:'center'
                        }}>{e.title}</span>
                        {/* {clicked && item === e.title ?
                            <> */}
                        <span style={{ ...Typo.h6, marginTop: 10, padding: 15, 
                            textAlign:'center'
                        }}>{e.description}</span>
                        {/* <span style={{ ...Typo.h4, font: "menu", backgroundColor: TColors.blue, padding: 10, borderRadius: 30, color: TColors.white }}>Read More ..</span> */}
                        {/* </>
                            : null
                        } */}
                    </div>
                </FRContainer>
            )}

        </div >
    )
}


const styles = {
    mainWrapper: {
        width: "100%",
        minHeight: '30vh',
        display: "flex",
        flexWrap: 'wrap',
        justifyContent: "center",
        alignItems: "center",
        zIndex: 0,
        backgroundColor: TColors.white
    },
    contentWrapper: {
        flex: 1,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        backgroundRepeat: "no-repeat",
        backgroundSize: 'cover',
        backgroundPosition: "center",
        minHeight: "40vh",
        minWidth: "45vw",
        transition: 'all 0.3s ease',
        // cursor: "pointer",
        // transform: 'translate3d(0px, 0px, 0.01px)',
        transformOrigin: "50% 50%",
        transformStyle: "flat",
        font: 'menu',
        // backgroundColor: TColors.background
        // overFlow: 'hidden'
    }
}

export default PartsShadowBox
