import React, { useState } from "react";
import { FRContainer, FS, TColors } from "../../Components/DefaultComponents";
import Typo from "../../Components/Typo";
import {
  CustomButton,
  TextInput1,
  WhiteBackground,
} from "../../Components/UIComponent";
import { AboutUSData } from "../../WebData/ContentData";
import { Ticon } from "../Header/HeaderRight";
import WebLeads from "../contact_us/WebLeads";

export const PartRequestFeatures = (props) => {
  return (
    <WhiteBackground
      style={{
        flex: 1,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        background: TColors.textBackground,
      }}
    >
      {AboutUSData.keyFeatures.features.map((e, i) => (
        <FRContainer key={i} style={{ alignItems: "flex-start" }}>
          <Ticon
            iconName={e.icon}
            style={{ color: TColors.primary, fontSize: FS.h1, width: 50 }}
          />
          <FRContainer
            style={{ flexDirection: "column", alignItems: "flex-start" }}
          >
            <span style={{ ...Typo.h5, marginBottom: "0.5em" }}>{e.title}</span>
            <span
              style={{
                ...Typo.p,
                marginBottom:
                  i === AboutUSData.keyFeatures.features.length - 1 ? 0 : "2em",
              }}
            >
              {e.smallDescription}
            </span>
          </FRContainer>
        </FRContainer>
      ))}
    </WhiteBackground>
  );
};

export const PartRequestForm = (props) => {
  const [name, setName] = useState();
  const [number, setNumber] = useState();
  const [email, setEmail] = useState();
  const [zip, setZip] = useState();
  const [make, setMake] = useState();
  const [model, setModel] = useState();
  const [part, setPart] = useState();
  const [year, setYear] = useState();

  const [agreed, setAgreed] = useState(false);
  const [messageSent, setMessageSent] = useState("");

  let validate =
    name &&
    name.length > 2 &&
    number &&
    number.length > 9 &&
    email &&
    zip &&
    make &&
    make.length > 3 &&
    // &&
    // model && model.length > 3
    part &&
    part.length > 3 &&
    year &&
    year >= 1800
      ? true
      : false;

  const SubmitFunc = async () => {
    const contactUsObj = {};
    const partRequestObj = {
      name: name,
      email: email,
      number: number,
      zip: zip,
      make: make,
      model: model,
      part: part,
      year: year,
    };
    await WebLeads(partRequestObj, contactUsObj)
      .then((res) => {
        // put inside API result response
        setName("");
        setNumber("");
        setEmail("");
        setZip("");
        setMake("");
        setModel("");
        setPart("");
        setYear("");
        setAgreed(false);
        document.getElementById("partclick").checked = false;
        setMessageSent(
          `"Your request has been sent successfully ! our agent will call you."`
        );
      })
      .catch((error) => console.log(error));
  };

  return (
    <FRContainer style={{ ...styles.PRFWrapper }}>
      <WhiteBackground style={{ flex: 1, background: TColors.textBackground }}>
        <span style={{ ...styles.headerText }}>Find a part now</span>

        <FRContainer style={{ flexWrap: "wrap", flex: 1, marginTop: "2vh" }}>
          <FRContainer
            style={{ minWidth: 300, flexDirection: "column", flex: 1 }}
          >
            <TextInput1
              placeholder="NAME *"
              onChange={(event) => setName(event.target.value)}
              value={name}
            />

            <TextInput1
              placeholder="Contact Number *"
              onChange={(event) => setNumber(event.target.value)}
              value={number}
              maxLength={10}
              type="tel"
            />

            <TextInput1
              placeholder="Email ID*"
              onChange={(event) => setEmail(event.target.value)}
              value={email}
              type="email"
            />

            <TextInput1
              placeholder="Zip Code*"
              onChange={(event) => setZip(event.target.value)}
              value={zip}
            />
          </FRContainer>

          <FRContainer
            style={{ minWidth: 300, flexDirection: "column", flex: 1 }}
          >
            <TextInput1
              placeholder="Make *"
              onChange={(event) => setMake(event.target.value)}
              value={make}
            />

            <TextInput1
              placeholder="Model *"
              onChange={(event) => setModel(event.target.value)}
              value={model}
            />

            <TextInput1
              placeholder="Part *"
              onChange={(event) => setPart(event.target.value)}
              value={part}
            />

            <TextInput1
              placeholder="Year *"
              onChange={(event) => setYear(event.target.value)}
              value={year}
              maxLength={10}
              type="number"
            />
          </FRContainer>
        </FRContainer>

        <FRContainer style={{ flexDirection: "column", marginTop: 10 }}>
          <FRContainer>
            <input
              id="partclick"
              onClick={() => setAgreed(!agreed)}
              type="checkbox"
              style={{
                height: 24,
                width: 24,
                marginRight: 10,
                borderRadius: "50%",
              }}
            />
            <span style={{ ...Typo.h6 }}>
              I have read and agree to the terms & conditions
            </span>
          </FRContainer>
          {!validate ? null : (
            <CustomButton
              clicked={agreed}
              btnText="Submit"
              btnClick={() => SubmitFunc()}
            />
          )}
          {messageSent ? (
            <span style={{ ...styles.headerText, ...Typo.h3 }}>
              {messageSent}
            </span>
          ) : null}
        </FRContainer>
      </WhiteBackground>
    </FRContainer>
  );
};

const styles = {
  PRFWrapper: {
    width: "100%",
    flex: 1,
    alignItems: "stretch",
  },
  headerText: {
    ...Typo.h3,
    color: TColors.blue,
  },
};
