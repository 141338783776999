import React, { useState } from "react";
import {
  FRContainer,
  TColors,
} from "../../../Components/DefaultComponents";
import Typo from "../../../Components/Typo";
import {
  TextInput1,
  TextInputTextArea,
  WhiteBackground,
  CustomButton,
} from "../../../Components/UIComponent";
import WebLeads from "../WebLeads";

const ContactForm = (props) => {
  const [agreed, setAgreed] = useState(false);

  const [name, setName] = useState();
  const [number, setNumber] = useState();
  const [email, setEmail] = useState();
  const [subject, setSubject] = useState();
  const [message, setMessage] = useState();
  const [messageSent, setMessageSent] = useState("");

  let validate =
    name &&
    name.length > 2 &&
    number &&
    number.length == 10 &&
    email &&
    subject &&
    subject.length > 3 &&
    message &&
    message.length > 3 &&
    agreed
      ? true
      : false;

  const SubmitFunc = async () => {
    const contactUsObj = {
      name: name,
      number: number,
      email: email,
      subject: subject,
      message: message,
    };
    const partRequestObj = {};
    await WebLeads(partRequestObj, contactUsObj)
      .then((res) => {
        // put inside API result response
        setAgreed();
        setNumber();
        setName("");
        setEmail("");
        setSubject("");
        setMessage("");
        document.getElementById("messagecheck").checked = false;
        setMessageSent(
          `"Your request has been sent successfully ! our agent will call you."`
        );
      })
      .catch((error) => console.log(error));
  };

  return (
    <WhiteBackground style={{ background: TColors.textBackground }}>
      <FRContainer
        style={{
          flexDirection: "column",
          flex: 1,
          alignItems: "stretch",
          gap: 10,
        }}
      >
        <FRContainer style={{ flexWrap: "wrap", flex: 1, gap: 10 }}>
          <TextInput1
            placeholder="NAME *"
            onChange={(event) => setName(event.target.value)}
            value={name}
          />
          <TextInput1
            placeholder="Contact Number *"
            onChange={(event) => setNumber(event.target.value)}
            value={number}
            maxLength={10}
            type="tel"
          />
        </FRContainer>
        <FRContainer
          style={{ flexWrap: "wrap", flex: 1, gap: 10, width: "100%" }}
        >
          <TextInput1
            placeholder="Email ID*"
            onChange={(event) => setEmail(event.target.value)}
            value={email}
            type="email"
          />
          <TextInput1
            placeholder="Subject *"
            onChange={(event) => setSubject(event.target.value)}
            value={subject}
          />
        </FRContainer>

        <FRContainer style={{ flex: 1, width: "100%" }}>
          <TextInputTextArea
            placeholder="MESSAGE *"
            onChange={(event) => setMessage(event.target.value)}
            value={message}
            multiple={true}
            styleWrapper={{
              minHeight: "30vh",
              alignItems: "flex-start",
              paddingTop: 10,
            }}
            style={{ minHeight: "25vh", textTransform: "none" }}
          />
        </FRContainer>
      </FRContainer>
      <FRContainer style={{ flexDirection: "column", marginTop: 10 }}>
        <FRContainer>
          {agreed || !agreed ? (
            <input
              value={agreed}
              onClick={() => setAgreed(!agreed)}
              type="checkbox"
              id="messagecheck"
              style={{
                // height: FS.h3,
                // width: FS.h3,
                height: 24,
                width: 24,
                marginRight: 10,
                borderRadius: "50%",
              }}
            />
          ) : null}
          <span style={{ ...Typo.h6 }}>
            I agree that the submitted data is being collected and stored and I
            consent to the collection and use of my personal information.
          </span>
        </FRContainer>
        {!validate ? null : (
          <CustomButton
            clicked={agreed}
            btnText="Submit"
            btnClick={() => SubmitFunc()}
          />
        )}
        {messageSent ? (
          <span style={{ color: TColors.blue, ...Typo.h3 }}>{messageSent}</span>
        ) : null}
      </FRContainer>
    </WhiteBackground>
  );
};

export default ContactForm;
